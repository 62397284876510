import GoogleTracking from "../../../../common/ts/core/tracking/GoogleTracking";
import FeatureFlags, {TROVIT_TRACK_GA4_FILTERS} from "../../../../common/ts/FeatureFlags";

export interface TrackFilterWithGoogleAnalyticsData {
    filterType?: string;
    value: string;
    isSem: boolean;
}

export default class TrackFilterWithGoogleAnalyticsService {
    private readonly googleTracking: GoogleTracking;

    constructor(googleTracking: GoogleTracking) {
        this.googleTracking = googleTracking;
    }

    public execute(data: TrackFilterWithGoogleAnalyticsData): void {
            const filterType = data.filterType || 'Filter';
            const codLegacyName = data.isSem ? 'search_adwords_homes' : 'search_homes';
            this.googleTracking.trackEvent(codLegacyName + "-" + filterType + "-" + this.getSortMapping(data.value) + "_wl");
    }

    getSortMapping(sortName: string) {
        const sortMapping: any = {
            'price_per_meters_desc': 'price_m2_desc',
            'source_date_asc': 'src_date_asc',
            'price_per_meters': 'price_m2',
            'minBathrooms': 'bath_min',
            'maxBathrooms': 'bath_max',
            'minBedrooms': 'rooms_min',
            'maxBedrooms': 'rooms_max',
            'cities': 'city',
            'regions': 'region',
            'cityareas': 'city_area',
            'propertyTypes': 'prop_type',
        }

        return sortMapping[sortName] || sortName
    }
}
