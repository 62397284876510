import {EventBus} from "../../../../../../common/ts/events/EventBus";
import {
    CLOSE_BUTTON_CLICKED_EVENT_TYPE,
    PointOfCaptureFormContainer
} from "./PointOfCaptureFormContainer";
import UserContactDataStorageService from "../../../../../../common/ts/services/UserContactDataStorageService";
import {PointOfCapture} from "./CookieAlertsClosedRepository";
import {
    GoogleOneTapFlowEvent, GoogleOneTapSignInEvent,
    PointOfCaptureExitEvent, WebPushNotificationDeniedEvent, WebPushNotificationGrantedEvent, WhitelabelEvent
} from "../../../../../../common/ts/events/Events";
import {
    AlertCreationImpressionPayload,
    PointOfCaptureClosedPayload,
    SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE, TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE,
    SEND_ALERT_CREATION_EVENT_TYPE
} from "./PointsOfCapture";
import CookieService from "../../../../../../common/ts/services/CookieService";

const MOBILE_FIXED_BOTTOM_POINT_OF_CAPTURE_ID = "mobile-fixed-bottom-retention";

export class MobileFixedBottomPointOfCapture {
    private readonly eventBus: EventBus;
    private deviceDetectorByCss: HTMLElement | null;
    private htmlElement: HTMLElement | null;
    private formContainer: PointOfCaptureFormContainer;
    private readonly _showStyleClass = 'show';
    private flow: GoogleOneTapFlowEvent | null;
    private cookieMobileFixedBottomShowed: CookieMobileFixedBottomShowedRepository;

    constructor(eventBus: EventBus, storage: UserContactDataStorageService, cookieService: CookieService) {
        this.eventBus = eventBus;
        this.htmlElement = null;
        this.deviceDetectorByCss = null;
        this.formContainer = new PointOfCaptureFormContainer(storage, this.eventBus);
        this.flow = null;
        this.cookieMobileFixedBottomShowed = new CookieMobileFixedBottomShowedRepository(cookieService);
    }

    init(alertAlreadyCreated: boolean) {
        this.cookieMobileFixedBottomShowed.init();
        this.htmlElement = document.getElementById(MOBILE_FIXED_BOTTOM_POINT_OF_CAPTURE_ID);
        this.deviceDetectorByCss = document.getElementById(`${MOBILE_FIXED_BOTTOM_POINT_OF_CAPTURE_ID}-device`);
        if (alertAlreadyCreated || this.cookieMobileFixedBottomShowed.isAlreadyShowed()) {
            this.removeMe();
            if (this.deviceDetectorByCss?.checkVisibility()) {
                this.subscribeToEventsWithoutPointOfCapture()
            }
            return;
        } else {
            this.subscribeToEventsWithPointOfCapture()
        }
    }

    private removeMe = () => {
        this.htmlElement?.remove();
    }

    private subscribeToEventsWithoutPointOfCapture() {
        this.subscribeToEvents(this.emitNextStepWithoutPointOfCapture);
    }

    private subscribeToEventsWithPointOfCapture() {
        this.subscribeToEvents(this.launch);
    }

    private emitNextStepWithoutPointOfCapture = (event: WhitelabelEvent) => {
        const {payload: {flow}} = (event as PointOfCaptureExitEvent);
        this.flow = flow;
        this.emitNextStepEvent();
    }

    private emitNextStepEvent() {
        this.eventBus.emit(new PointOfCaptureExitEvent({flow: this.flow!!}));
    }

    private launch = (event: WhitelabelEvent) => {
        const {payload: {flow}} = (event as PointOfCaptureExitEvent);
        this.flow = flow;
        if (this.htmlElement) {
            this.show();
            this.formContainer.init(this.htmlElement);
            if (this.formContainer.isContentVisible()) {
                this.registerEvents();
                this.trackEvents();
                // this.subscribeToEvents();
            }
        }
    }

    private subscribeToEvents(callback: (event: WhitelabelEvent) => void) {
        this.eventBus.subscribeWithId(
            GoogleOneTapSignInEvent.TYPE,
            {
                id: `${GoogleOneTapSignInEvent.TYPE}-mobile-fixed-bottom-handler`,
                callback: callback,
            }
        )
        this.eventBus.subscribeWithId(
            WebPushNotificationGrantedEvent.TYPE,
            {
                id: `${WebPushNotificationGrantedEvent.TYPE}-mobile-fixed-bottom-handler`,
                callback: (event) => {
                    if (this.flow == GoogleOneTapFlowEvent.CLOSE) {
                        callback(event);
                    }
                },
            }
        )
        this.eventBus.subscribeWithId(
            WebPushNotificationDeniedEvent.TYPE,
            {
                id: `${WebPushNotificationDeniedEvent.TYPE}-mobile-fixed-bottom-handler`,
                callback: (event) => {
                    if (this.flow == GoogleOneTapFlowEvent.CLOSE) {
                        callback(event);
                    }
                },
            }
        )
    }

    private show() {
        this.htmlElement?.classList.add(this._showStyleClass);
    }

    private hide() {
        this.htmlElement?.classList.remove(this._showStyleClass);
    }

    private registerEvents() {
        this.registerCloseElementEvent();
        this.registerNotNowButtonElementEvent();
        this.registerCloseButtonClickedEvent();
        this.subscribeSubmitEvent();
    }

    private registerCloseElementEvent() {
        // @ts-ignore
        this.htmlElement?.querySelector('.js-close').addEventListener('click', this.closeElement);
    }

    private closeElement = () => {
        this.saveAsShowed();
        this.hide();
        this.dispatchPointOfCaptureClosed();
        this.emitNextStepEvent();
    }

    private registerNotNowButtonElementEvent() {
        // @ts-ignore
        this.htmlElement?.querySelector('.js-email-for-retention-no-now-button').addEventListener('click', this.closeNotNowButton);
    }

    private closeNotNowButton = ()=> {
        this.saveAsShowed();
        this.hide();
        this.emitNextStepEvent();
    }

    private dispatchPointOfCaptureClosed() {
        document.dispatchEvent(new CustomEvent<PointOfCaptureClosedPayload>(SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE, {detail: {'type': PointOfCapture.MOBILE_FIXED_BOTTOM}}));
    }

    private registerCloseButtonClickedEvent() {
        document.addEventListener(CLOSE_BUTTON_CLICKED_EVENT_TYPE, this.closeElement);
    }

    private trackEvents() {
        let sectionId = this.formContainer.getVisibleSection();
        if (sectionId) {
            document.dispatchEvent(new CustomEvent<AlertCreationImpressionPayload>(TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE, {detail: {'section': sectionId}}));
        }
    }

    private saveAsShowed() {
        this.cookieMobileFixedBottomShowed.saveAsShowed();
    }

    private subscribeSubmitEvent() {
        // @ts-ignore
        document.addEventListener(SEND_ALERT_CREATION_EVENT_TYPE, () => this.saveAsShowed());
    }
}

const MOBILE_FIXED_BOTTOM_SHOWED_NAME = 'trovitMobileFixedBottomShowed'

class CookieMobileFixedBottomShowedRepository {
    private cookieService: CookieService;
    private showed: boolean;

    constructor(cookieService: CookieService) {
        this.cookieService = cookieService;
        this.showed = false;
    }

    init() {
        const mobileFixedBottomShowedString = this.cookieService.getCookie(MOBILE_FIXED_BOTTOM_SHOWED_NAME);
        if (mobileFixedBottomShowedString) {
            this.showed = this.convertToBoolean(mobileFixedBottomShowedString);
        }
    }

    isAlreadyShowed() {
        return this.showed;
    }

    saveAsShowed() {
        this.showed = true;
        this.cookieService.setExpirableCookie(MOBILE_FIXED_BOTTOM_SHOWED_NAME, JSON.stringify(this.showed), 7);
    }

    private convertToBoolean(value: string): boolean {
        try {
            return JSON.parse(value.toLowerCase());
        } catch (e) {
            return false;
        }
    }
}
