import location from "../../../../common/ts/core/location";
import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import TrackFilterWithGoogleAnalyticsService, {TrackFilterWithGoogleAnalyticsData} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class PropertyTypesSelectorSubscriber {
    private eventBus: EventBus
    private userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-property-types-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;
                if (payload.id === "property-types-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: 'prop_type',
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData);
                    pageData.propertyType = payload.value
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();

                    this.userSearch.execute({
                        ...userSearchFilters,
                        propertyType: payload.value,
                    });
                }
            }
        });
    }
}