import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import location from "../../../../common/ts/core/location";
import {GetNumberOfListingsAjax} from "../../core/url/services/GetNumberOfListingsAjax";

export class ClearFiltersForm {
    clear(): void {
        this.clearSelectFilterElements()
        this.clearLinearSelectFilterElements()
    }

    private clearLinearSelectFilterElements(): void {
        document.querySelectorAll('.js-wl-linear-selector-filter-input')
            .forEach((element: Element) => {
                const checkbox = element as HTMLInputElement;
                checkbox.checked = false;
            });
    }

    private clearSelectFilterElements(): void {
        document.querySelectorAll('.js-wl-select')
            .forEach((element: Element) => {
                const select = element as HTMLSelectElement;
                if (select.options.length > 0) {
                    select.value = select.options[0].value;
                }
            });
    }
}

export default class ClearAllFormFiltersSubscriber {

    private readonly CLEAR_ALL_FORM_FILTERS_SELECTOR = '.clear-all-form-filters-button'

    private readonly getNumberOfListingsAjax: GetNumberOfListingsAjax;

    constructor(userSearchAjax: GetNumberOfListingsAjax) {
        this.getNumberOfListingsAjax = userSearchAjax;
    }

    init(pageData: TrovitSerpData) {
        const clearAllFormFiltersElements = document.querySelectorAll(this.CLEAR_ALL_FORM_FILTERS_SELECTOR);
        clearAllFormFiltersElements.forEach((clearAllFormFiltersElement: Element) => {
                clearAllFormFiltersElement.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.getNumberOfListingsAjax.execute(
                    TrovitSerpDataImpl.createWithDefaults(
                        this.clearAllFilters(pageData),
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams()
                );
                new ClearFiltersForm().clear()
            });
        });
    }

    private clearAllFilters(pageData: TrovitSerpData): TrovitSerpData {
        const propertiesToClear = [
            'areaMin',
            'areaMax',
            'roomsMin',
            'bathRoomsMin',
            'priceMin',
            'priceMax',
            'city',
            'cityArea',
            'region',
            'propertyType'
        ];

        propertiesToClear.forEach(property => {
            (pageData as any)[property] = undefined;
        });
        return pageData;
    }
}
