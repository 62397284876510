import TrovitPathPrefixPreponder from './TrovitPathPrefixPreponder';

export interface TrovitUserSearchUrlParams {
    originHost: string;
    typeId: string;
    what: string;
    isSem: boolean;
    currentOrigin: number;
    sortBy?: string;
    geoId?: string;
    roomsMin?: string;
    bathRoomsMin?: string;
    priceMin?: string;
    priceMax?: string;
    areaMin?: string;
    areaMax?: string;
    propertyType?: string;
    region?: string;
    city?: string;
    cityArea?: string;
    regionId?: number;
    cityId?: number;
    cityAreaId?: number;
}

function makeUserSearchUrlForStaticFilters(
    params: TrovitUserSearchUrlParams,
    userSearchWLLinksFeatureFlagsIsActive: boolean
): string {
    return userSearchWLLinksFeatureFlagsIsActive
        ? makeTrovitWlUserSearchUrl(params)
        : makeTrovitClassicUserSearchUrl(params);
}

function makeTrovitClassicUserSearchUrl(params: TrovitUserSearchUrlParams): string {
    const searchParams = buildSearchParams(params, 'classic', params.isSem);
    const paramsUrl = Object.entries(searchParams)
        .reduce((acc, [key, val]) => acc + `${key}.${val}/`, '');
    const cod = params.isSem ? 'search_adwords_homes' : 'search_homes';

    const path = TrovitPathPrefixPreponder.prependVerticalPrefixToPath(`/index.php/cod.${cod}/${paramsUrl}`);
    return `${params.originHost}${path}`;
}

function makeTrovitWlUserSearchUrl(params: TrovitUserSearchUrlParams): string {
    const searchParams = buildSearchParams(params, 'wl', params.isSem);
    const paramsUrl = Object.entries(searchParams)
        .map(([key, val]) => `${key}=${val}`)
        .join('&');
    return `${params.originHost}/search?${paramsUrl}`;
}

function buildSearchParams(params: TrovitUserSearchUrlParams, type: 'classic' | 'wl', isSem: Boolean): { [name: string]: string } {
    const {
        typeId: typeParam,
        what,
        sortBy = "",
        currentOrigin = 1
    } = params;

    const searchParams: { [name: string]: string } = {
        type: typeParam,
    };

    if (type === 'classic') {
        searchParams['what_d'] = what;
        searchParams['sug'] = '0';
        searchParams['isUserSearch'] = '1';
    } else {
        searchParams['text'] = what;
    }

    if (isSem) {
        searchParams["origin"] = currentOrigin.toString();
    }
    searchParams["order_by"] = sortBy ? sortBy : 'relevance';

    addOptionalParams(searchParams, params);

    return searchParams;
}

function addOptionalParams(searchParams: { [name: string]: string }, params: TrovitUserSearchUrlParams) {
    const {
        geoId,
        roomsMin,
        bathRoomsMin,
        priceMin,
        priceMax,
        areaMin,
        areaMax,
        propertyType,
        region,
        city,
        cityArea,
        regionId,
        cityId,
        cityAreaId
    } = params;

    if (geoId) searchParams['geo_id'] = geoId;
    if (roomsMin && roomsMin !== '0') searchParams['rooms_min'] = roomsMin;
    if (bathRoomsMin && bathRoomsMin !== '0') searchParams['bathrooms_min'] = bathRoomsMin;
    if (priceMin) searchParams['price_min'] = priceMin;
    if (priceMax) searchParams['price_max'] = priceMax;
    if (areaMin) searchParams['area_min'] = areaMin;
    if (areaMax) searchParams['area_max'] = areaMax;
    if (propertyType) searchParams['property_type'] = encodeURI(propertyType);
    if (region) searchParams['region'] = encodeURI(region);
    if (city) searchParams['city'] = encodeURI(city);
    if (cityArea) searchParams['city_area'] = encodeURI(cityArea);
    if (regionId) searchParams['region_id'] = regionId.toString();
    if (cityId) searchParams['city_id'] = cityId.toString();
    if (cityAreaId) searchParams['city_area_id'] = cityAreaId.toString();
}

export default makeUserSearchUrlForStaticFilters;
