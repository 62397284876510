import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import {RedirectToUserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {ModalBlock} from "../../core/url/services/GetNumberOfListingsAjax";

export const SHOW_RESULTS_BUTTON_ID = 'show-results-button'

export default class ShowResultsSubscriber {

    private readonly redirectToUserSearch: RedirectToUserSearch;
    private readonly modalBlock: ModalBlock;

    constructor(
        redirectToUserSearch: RedirectToUserSearch,
        modalBlock: ModalBlock
    ) {
        this.redirectToUserSearch = redirectToUserSearch;
        this.modalBlock = modalBlock;
    }

    init(pageData: TrovitSerpData) {
        const showResultsButton = document.getElementById(SHOW_RESULTS_BUTTON_ID);
        if (showResultsButton) {
            showResultsButton.addEventListener('click', (_event) => {
                if (this.hasResultsToShow(showResultsButton)) {
                    this.showResults(pageData)
                }
            });
        }
    }

    private hasResultsToShow(showResultsButton: HTMLElement): boolean {
        const numberMatch = showResultsButton.textContent?.match(/\d+/);
        if (numberMatch) {
            return parseInt(numberMatch[0]) > 0;
        }
        return false;
    }

    private showResults(pageData: TrovitSerpData): void {
        this.modalBlock.blockFullscreenModal();
        this.redirectToUserSearch.execute(
            TrovitSerpDataImpl.createWithDefaults(
                pageData,
                location.getOrigin()
            ).toTrovitUserSearchUrlParams()
        );
    }
}
