import {TROVIT_PERSONALIZED_GOOGLE_ADS} from "../../core/components/GdprCookieDisclaimer";

declare global {
    interface Window {
        googletag: any;
    }
}

export interface GoogleTag {
    cmd: {
        push: (callback: () => void) => void;
    },
    pubads: () => {
        setPrivacySettings: (settings: { nonPersonalizedAds: boolean }) => void;
        enableSingleRequest: () => void;
        collapseEmptyDivs: (collapse: boolean) => void;
        refresh: (slots?: any[]) => void;
        getSlots: () => any[];
    }
}

const DISPLAY_CONTAINER_CLASS = ".display-container";

const trovitPersonalizedGoogleAds = (): boolean => {
    return document.cookie.includes(TROVIT_PERSONALIZED_GOOGLE_ADS + "=1");
}

const buildCallback = (
    googletag: any,
    unitPath: string,
    sizes: any[],
    identifier: string,
    variables: any
) => {
    return () => {
        const slot = googletag.defineSlot(unitPath, sizes, identifier)
            .addService(googletag.pubads());
        Object.entries(variables).forEach(([key, value]) => {
            slot.setTargeting(key, value);
        });

        const filtersToSend = [
            'price_min',
            'price_max',
            'new_build',
            'parking',
            'city',
            'region',
            'make',
            'model',
            'property_type',
            'category'
        ];

        filtersToSend.forEach(filter => {
            slot.setTargeting(filter, '');
        });

        slot.setTargeting("atomicPageType", '');
        slot.setTargeting("q_what", '');
        slot.setTargeting("q_where", '');
        slot.setTargeting("banner", identifier);
        slot.setTargeting("isNewUser", "0");
        slot.setTargeting("isTrovitSnippet", "0");

        if (trovitPersonalizedGoogleAds()) {
            googletag.pubads().setPrivacySettings({nonPersonalizedAds: false});
        } else {
            googletag.pubads().setPrivacySettings({nonPersonalizedAds: true});
        }

        googletag.pubads().enableSingleRequest();
        googletag.pubads().collapseEmptyDivs(true);
        googletag.pubads().enableLazyLoad({
            fetchMarginPercent: 100,
            renderMarginPercent: 50,
            mobileScaling: 3.0
        });
        googletag.enableServices();
        googletag.display(identifier);
    }
}

class GoogleDisplay {

    private readonly googleTag: GoogleTag;
    private readonly dom: any;

    constructor(googleTag: GoogleTag, dom: any) {
        this.googleTag = googleTag;
        this.dom = dom;
    }

    init() {
        window.addEventListener('load', () => {
            this.dom.selectAll(DISPLAY_CONTAINER_CLASS)?.forEach((containerEl: Element) => {
                const identifier = containerEl.getAttribute("data-identifier")!!;
                const unitPath = containerEl.getAttribute("data-ad-unit-path")!!;
                const sizes = JSON.parse(containerEl.getAttribute("data-sizes")!!);
                const variables = JSON.parse(containerEl.getAttribute("data-target")!!);
                this.googleTag.cmd.push(buildCallback(this.googleTag, unitPath, sizes, identifier, variables));
            });
        });
    }
}

export default GoogleDisplay