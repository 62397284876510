import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import location from "../../../../common/ts/core/location";
import {RedirectToUserSearch} from "../../core/url/services/RedirectToUserSearch";

export default class ClearAllFiltersAndReloadSubscriber {

    private readonly CLEAR_ALL_FILTERS_SELECTOR = '.clear-all-filters-button'
    private readonly redirectSearch: RedirectToUserSearch

    constructor(
        redirectSearch: RedirectToUserSearch,
    ) {
        this.redirectSearch = redirectSearch
    }

    init(pageData: TrovitSerpData) {
        const clearAllFiltersElements = document.querySelectorAll(this.CLEAR_ALL_FILTERS_SELECTOR);
        clearAllFiltersElements.forEach((clearAllFiltersElement: Element) => {
                clearAllFiltersElement.addEventListener('click', (event) => {
                event.preventDefault();
                event.stopPropagation();
                this.redirectSearch.execute(
                    TrovitSerpDataImpl.createWithDefaults(
                        this.clearAllFilters(pageData),
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams()
                );
            });
        });
    }

    private clearAllFilters(pageData: TrovitSerpData): TrovitSerpData {
        const propertiesToClear = [
            'areaMin',
            'areaMax',
            'roomsMin',
            'bathRoomsMin',
            'priceMin',
            'priceMax',
            'city',
            'cityArea',
            'region',
            'propertyType'
        ];

        propertiesToClear.forEach(property => {
            (pageData as any)[property] = undefined;
        });
        return pageData;
    }
}
