export default class TrovitPathPrefixPreponder {

    private static readonly PREFIX_MAP: { [key: string]: string } = {
        "at.trovit.com": "/immobilien",
        "de.trovit.com": "/immobilien",
        "ua.trovit.com": "/nedvizhimost",
        "ru.trovit.com": "/nedvizhimost",
        "australia.trovit.com": "/property",
        "th.trovit.com": "/baan"
    };

    static prependVerticalPrefixToPath(path: string): string {
        let host = window.location.host;
        const port = window.location.port;
        if (host.endsWith(".preprod")) {
            host = host.replace(".preprod", "");
        }
        const prefix = this.PREFIX_MAP[host];
        if (prefix && this.isNotDenEnv(port)) {
            return `${prefix}${path}`;
        }
        return path;
    }

    private static isNotDenEnv = (port: string): boolean => port !== "8081";}