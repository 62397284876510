import {TrovitPageData, TrovitSerpData, TrovitSerpDataImpl} from "../../../TrovitPageData";
import UserContactDataStorageService, {
    UserContactDataType
} from "../../../../../../common/ts/services/UserContactDataStorageService";
import AdFormService from "../../../../../../common/ts/services/AdFormService";
import {TopLeftPointOfCapture} from "./TopLeftPointOfCapture";
import {BottomPointOfCapture} from "./BottomPointOfCapture";
import {CookieAlertsCreatedRepository} from "./CookieAlertsCreatedRepository";
import {PointOfCaptureFormContainer} from "./PointOfCaptureFormContainer";
import {AutoOpenModalPointOfCapture} from "./AutoOpenModalPointOfCapture";
import {CookieAlertsClosedRepository, PointOfCapture} from "./CookieAlertsClosedRepository";
import {EventBus} from "../../../../../../common/ts/events/EventBus";
import {MobileFixedBottomPointOfCapture} from "./MobileFixedBottomPointOfCapture";
import CookieService from "../../../../../../common/ts/services/CookieService";

export const TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE = 'trackAlertCreationImpression';
export const SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE = 'savePointOfCaptureClosed';
export const SEND_ALERT_CREATION_EVENT_TYPE = 'sendAlertCreation';

export default class PointsOfCapture {
    private pageData: TrovitPageData | null;
    private pageDataFilters: PageDataFilters | null;
    private topLeftRetention: TopLeftPointOfCapture;
    private bottomRetention: BottomPointOfCapture;
    private autoOpenModalPointOfCapture: AutoOpenModalPointOfCapture;
    private mobileFixedBottomPointOfCapture: MobileFixedBottomPointOfCapture;
    private adFormService: AdFormService;
    private storage: UserContactDataStorageService;
    private alertsCreated: CookieAlertsCreatedRepository;
    private alertsClosed: CookieAlertsClosedRepository;
    private eventBus: EventBus;

    constructor(
        adFormService: AdFormService,
        topLeftRetention: TopLeftPointOfCapture,
        bottomRetention: BottomPointOfCapture,
        storage: UserContactDataStorageService,
        autoOpenModalPointOfCapture: AutoOpenModalPointOfCapture,
        mobileFixedBottomPointOfCapture: MobileFixedBottomPointOfCapture,
        eventBus: EventBus,
        cookieService: CookieService) {
        this.pageData = null;
        this.pageDataFilters = null;
        this.adFormService = adFormService;
        this.topLeftRetention = topLeftRetention;
        this.bottomRetention = bottomRetention;
        this.autoOpenModalPointOfCapture = autoOpenModalPointOfCapture;
        this.mobileFixedBottomPointOfCapture = mobileFixedBottomPointOfCapture;
        this.storage = storage;
        this.alertsCreated = new CookieAlertsCreatedRepository(cookieService);
        this.alertsClosed = new CookieAlertsClosedRepository(cookieService);
        this.eventBus = eventBus;
    }

    init(pageData: TrovitSerpData) {
        this.pageData = pageData;
        this.pageDataFilters = PageDataFiltersGenerator.generateFrom(pageData);
        this.alertsCreated.init();
        const alertAlreadyCreated = this.alertsCreated.exist(this.createAlertDataByPageData());
        this.alertsClosed.init();
        this.registerEvents();
        this.topLeftRetention.init(alertAlreadyCreated);
        this.bottomRetention.init(alertAlreadyCreated, this.alreadyClosed);
        this.autoOpenModalPointOfCapture.init(alertAlreadyCreated, this.alreadyClosed);
        this.mobileFixedBottomPointOfCapture.init(alertAlreadyCreated);
    }

    private alreadyClosed = (pointOfCapture: PointOfCapture) => {
        return this.alertsClosed.exist({
            pointOfCapture: pointOfCapture,
            what: this.pageData!!.what,
            filters: this.pageDataFilters!!,
        });
    }

    private registerEvents() {
        // @ts-ignore
        document.addEventListener(TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE, this.trackAlertCreationImpression);
        // @ts-ignore
        document.addEventListener(SEND_ALERT_CREATION_EVENT_TYPE, this.sendAlertCreation);
        // @ts-ignore
        document.addEventListener(SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE, this.savePointOfCaptureClosed);
    }

    private savePointOfCaptureClosed = (e: CustomEvent<PointOfCaptureClosedPayload>) => {
        if (this.pageData) {
            this.alertsClosed.add({
                pointOfCapture: e.detail.type,
                what: this.pageData.what,
                filters: this.pageDataFilters!!,
            });
        }
    }

    private trackAlertCreationImpression = (e: CustomEvent<AlertCreationImpressionPayload>) => {
        if (this.pageData) {
            this.adFormService.alertCreationImpression(this.pageData, e.detail.section, this.pageDataFilters!!);
        }
    }

    private sendAlertCreation = (e: CustomEvent<AlertCreationPayload>) => {
        let inputEmail = e.detail.email;
        this.adFormService
            .alertCreation(this.pageData!!, e.detail.section, inputEmail, this.pageDataFilters!!)
            .then(response => {
                response.json().then((responseBody: AlertCreationResponseBody) => {
                    if (response.status === 201) {
                        e.detail.object.showSuccess(responseBody.feedBackMessage);
                        this.storage.storeUserValue(UserContactDataType.EMAIL, inputEmail);
                        this.alertsCreated.add(this.createAlertDataByPageData());
                    } else {
                        e.detail.object.showError(responseBody.feedBackMessage);
                    }
                })
            });
    }

    private createAlertDataByPageData() {
        return {
            what: this.pageData!!.what,
            filters: this.pageDataFilters!!,
        };
    }
}

export interface AlertCreationPayload {
    section: number,
    email: string,
    object: PointOfCaptureFormContainer,
}

export interface AlertCreationImpressionPayload {
    section: number,
}

export interface AlertCreatedPayload {
    object: PointOfCaptureFormContainer,
}

export interface AlertCreationResponseBody {
    feedBackMessage: string,
    isSuccessful: boolean,
    message: string,
}

export interface PointOfCaptureClosedPayload {
    type: PointOfCapture,
}

export interface PageDataFilters {
    type?: number,
    geo_id?: string,
    rooms_min?: string,
    bathrooms_min?: string,
    price_min?: string,
    price_max?: string,
    city_area?: string,
    city?: string,
    region?: string,
    property_type?: string,
    area_min?: number,
    area_max?: number,
    order_by?: string,
}

export class PageDataFiltersGenerator {
    static generateFrom(pageData: TrovitPageData): PageDataFilters {
        if (pageData !instanceof TrovitSerpDataImpl) {
            return {
                type: pageData.typeId,
            };
        }

        const trovitSerpDataImpl = pageData as TrovitSerpData;
        return {
            type: trovitSerpDataImpl.typeId,
            geo_id: trovitSerpDataImpl.geoId,
            rooms_min: trovitSerpDataImpl.roomsMin,
            bathrooms_min: trovitSerpDataImpl.bathRoomsMin,
            price_min: trovitSerpDataImpl.priceMin,
            price_max: trovitSerpDataImpl.priceMax,
            city_area: trovitSerpDataImpl.cityArea,
            city: trovitSerpDataImpl.city,
            region: trovitSerpDataImpl.region,
            property_type: trovitSerpDataImpl.propertyType,
            area_min: trovitSerpDataImpl.areaMin,
            area_max: trovitSerpDataImpl.areaMax,
            order_by: trovitSerpDataImpl.orderBy,
        };
    }
}