import {ListingsRepository} from "../../core/api/ApiRepository";
import {SimilarListingsServiceQuery} from "./SimilarListingsServiceQuery";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {SerpListingsUpdatedEvent} from "../../../../common/ts/events/Events";
import {addSnippetClickedEventListener} from "../subscribers/ListingVisited";
import {addExtendedDescriptionClickEventListener} from "../subscribers/ToggleDescription";

export default class SimilarListingsService {
    private readonly eventBus: EventBus;
    private readonly listingsRepository: ListingsRepository;

    constructor(
        eventBus: EventBus,
        listingsRepository: ListingsRepository
    ) {
        this.eventBus = eventBus;
        this.listingsRepository = listingsRepository;
    }

    public execute(query: SimilarListingsServiceQuery): void {
        if (query.isDirectCountry) {
            return;
        }
        const listingElement = query.listing;
        if (listingElement) {
            this.listingsRepository.getSimilarListings(query).then((response,) => {
                const tempDiv = document.createElement('div');
                const similarListingsContent = response.trim();
                if (similarListingsContent !== '') {
                    tempDiv.innerHTML = similarListingsContent;
                    const articleCount = tempDiv.querySelectorAll('article').length;
                    tempDiv.querySelectorAll('.js-show-extended-description')
                        .forEach((element) => addExtendedDescriptionClickEventListener(element, this.eventBus));
                    tempDiv.querySelectorAll('.extended-description a')
                        .forEach((anchor) => addSnippetClickedEventListener(anchor, this.eventBus));
                    Array.from(tempDiv.children).forEach(listing => {
                        listingElement.insertAdjacentElement('afterend', listing);
                    });
                    this.eventBus.emit(new SerpListingsUpdatedEvent());
                } else {
                    console.info('Error: similar Listings content is empty');
                }
            });
        }
    }
}
