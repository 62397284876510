import {Vertical} from "../../../common/ts/types/Vertical";

const COOKIE_ID: string = 'visited';
const COOKIE_NAME: string = 'visited';
const COOKIE_EXPIRATION_DAYS: number = 365;

export interface VisitedListing {
    id: string;
    isPremium: boolean;
    modalHasBeenSeen: boolean;
}

export class VisitedCookie {
    public addVisitedListing(listing: VisitedListing) {
        const visitedListings: VisitedListing[] = this.getVisitedListings();
        const index: number = visitedListings.findIndex((visitedListing: VisitedListing) => visitedListing.id === listing.id);
        if (index === -1) {
            visitedListings.push(listing);
            this.setVisitedCookie(visitedListings);
        }
    }

    public isVisited(listingId: string): boolean {
        const visitedListings: VisitedListing[] = this.getVisitedListings();
        return visitedListings.some((visitedListing: VisitedListing) => visitedListing.id === listingId);
    }

    public theModalHasBeenSeen(listingId: string): boolean {
        const visitedListings: VisitedListing[] = this.getVisitedListings();
        return visitedListings.some((visitedListing: VisitedListing) => visitedListing.id === listingId && visitedListing.modalHasBeenSeen);
    }

    public getVisitedListings(): VisitedListing[] {
        const cookieList: string[] = document.cookie.split(';');
        const cookie = cookieList.find(c => c.trim().startsWith(COOKIE_NAME));
        if (cookie) {
            const value: string = cookie.trim().substring(COOKIE_NAME.length + 1);
            const parsedValue: any = JSON.parse(decodeURIComponent(value));
            return parsedValue[Vertical.HOMES] || [];
        }
        return [];
    }

    private setVisitedCookie(listings: VisitedListing[]) {
        const value: string = JSON.stringify({
            id: COOKIE_ID,
            [Vertical.HOMES]: listings
        });
        const expirationTimeInMs: number = COOKIE_EXPIRATION_DAYS * 24 * 60 * 60 * 1000;
        const date: Date = new Date();
        date.setTime(date.getTime() + expirationTimeInMs);
        const expires: string = `expires=${date.toUTCString()}`;
        const htmlEncodedValue: string = encodeURIComponent(value);
        document.cookie = `${COOKIE_NAME}=${htmlEncodedValue}; ${expires}; Path=/`;
    }

    setModalHasBeenSeen(listingId: string) {
        const visitedListings: VisitedListing[] = this.getVisitedListings();
        const index: number = visitedListings.findIndex((visitedListing: VisitedListing) => visitedListing.id === listingId);
        if (index !== -1) {
            visitedListings[index].modalHasBeenSeen = true;
            this.setVisitedCookie(visitedListings);
        }
    }
}