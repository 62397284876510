import {SerpSnippetClickedEvent} from "../../../../common/ts/events/Events";
import {EventBus} from "../../../../common/ts/events/EventBus";
import SimilarListingsService from "../services/SimilarListingsService";
import {TrovitSerpData} from "../TrovitPageData";
import {VisitedCookie, VisitedListing} from "../VisitedCookie";

export default class SimilarListingsSubscriber {
    private eventBus: EventBus
    private similarListingService: SimilarListingsService
    private visitedCookie: VisitedCookie
    private listingClicked: VisitedListing[];

    constructor(
        eventBus: EventBus,
        similarListingService: SimilarListingsService,
        visitedCookie: VisitedCookie,
    ) {
        this.eventBus = eventBus
        this.similarListingService = similarListingService
        this.visitedCookie = visitedCookie
        this.listingClicked = this.visitedCookie.getVisitedListings();
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SerpSnippetClickedEvent.TYPE, {
            id: 'trovit-serp-snippet-clicked-event-similar-listings-subscriber',
            callback: (event) => {
                const {payload}: SerpSnippetClickedEvent = event as SerpSnippetClickedEvent;
                const listing = payload.listing
                const listingId = listing.getAttribute('data-id') || '';
                const excludeListings = this.getListingIds();
                const isSimilarAttribute = listing.getAttribute('data-is-similar');
                const isSimilar = isSimilarAttribute != null && isSimilarAttribute == 'true'
                const pageViewId = pageData.pageViewId;
                const geoId = pageData.geoId || '';
                const type = pageData.typeId;
                if (!isSimilar && !this.listingClicked.find((visitedListing) => visitedListing.id === listingId)){
                    this.similarListingService.execute({
                        listingId: listingId,
                        pageViewId: pageViewId,
                        excludeListings: excludeListings,
                        geoId: geoId,
                        type: type,
                        listing: listing,
                        currentOrigin: pageData.currentOrigin,
                        isDirectCountry: pageData.isDirectCountry,
                    })
                    this.listingClicked = this.visitedCookie.getVisitedListings();
                }
            }
        });
    }

    getListingIds() {
        const listingIds: string[] = [];
        document.querySelectorAll('.snippet-listing')
            .forEach((item) => {
                listingIds.push(item.getAttribute('data-id') || '');
            });
        return listingIds;
    }
}
