import {EventBus} from "../../../../../../common/ts/events/EventBus";
import UserContactDataStorageService from "../../../../../../common/ts/services/UserContactDataStorageService";
import {FullPageModalCloseEvent, FullPageModalOpenEvent} from "../../../../../../common/ts/events/Events";
import {
    AlertCreatedPayload,
    AlertCreationImpressionPayload,
    TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE
} from "./PointsOfCapture";
import {PointOfCaptureFormContainer, ALERT_CREATED_EVENT_TYPE} from "./PointOfCaptureFormContainer";

const TOP_LEFT_POINT_OF_CAPTURE_MODAL_ID = "top-left-retention-modal";

export class TopLeftPointOfCapture {
    private readonly eventBus: EventBus;
    private htmlElement: HTMLElement | null;
    private formContainer: PointOfCaptureFormContainer;

    constructor(eventBus: EventBus, storage: UserContactDataStorageService) {
        this.eventBus = eventBus;
        this.htmlElement = null;
        this.formContainer = new PointOfCaptureFormContainer(storage, this.eventBus);
    }

    init(alertAlreadyCreated: boolean) {
        this.htmlElement = document.getElementById("top-left-retention");
        if (alertAlreadyCreated) {
            this.removeMe();
            return;
        }
        if (this.htmlElement) {
            this.htmlElement.classList.add('visible');
            if (this.htmlElement.checkVisibility()) {
                let modal = document.getElementById(TOP_LEFT_POINT_OF_CAPTURE_MODAL_ID);
                if (modal) {
                    this.formContainer.init(modal);
                    this.registerEvents();
                    this.trackEvents();
                }
            }
        }
    }

    private registerEvents() {
        this.registerOpenModalEvent();
        this.registerAlertCreatedEvent();
    }

    private registerOpenModalEvent() {
        this.htmlElement?.querySelector('button')?.addEventListener('click', this.openModal);
    }

    private openModal = (event: Event) => {
        event.stopPropagation();
        event.preventDefault();

        this.eventBus.emit(new FullPageModalOpenEvent({id: TOP_LEFT_POINT_OF_CAPTURE_MODAL_ID}));
        this.dispatchAlertCreationImpression(parseInt(this.htmlElement!!.getAttribute("data-step-2-section")!!));
        this.formContainer.applyFocusToInput();
    };

    private dispatchAlertCreationImpression(section: number) {
        document.dispatchEvent(new CustomEvent<AlertCreationImpressionPayload>(TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE, {detail: {'section': section}}));
    }

    private trackEvents() {
        this.dispatchAlertCreationImpression(parseInt(this.htmlElement!!.getAttribute("data-step-1-section")!!));
    }

    private registerAlertCreatedEvent() {
        // @ts-ignore
        document.addEventListener(ALERT_CREATED_EVENT_TYPE, this.alertCreated);
    }

    private alertCreated = (e: CustomEvent<AlertCreatedPayload>) => {
        setTimeout(this.removeMe, 3000);
    }

    private removeMe = () => {
        this.eventBus.emit(new FullPageModalCloseEvent({id: TOP_LEFT_POINT_OF_CAPTURE_MODAL_ID}));
        this.htmlElement?.remove();
    }
}