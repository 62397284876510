import CookieService from "../../../../../../common/ts/services/CookieService";
import {PageDataFilters} from "./PointsOfCapture";

const ALERTS_CREATED_COOKIE_NAME = 'trovitCreatedAlerts'

export class CookieAlertsCreatedRepository {
    private cookieService: CookieService;
    private alertsCreated: AlertData[];

    constructor(cookieService: CookieService) {
        this.cookieService = cookieService;
        this.alertsCreated = [];
    }

    init() {
        const alertsCreatedString = this.cookieService.getCookie(ALERTS_CREATED_COOKIE_NAME);
        if (alertsCreatedString) {
            this.alertsCreated = JSON.parse(alertsCreatedString);
        }
    }

    add(alertData: AlertData) {
        this.alertsCreated.push(alertData);
        this.cookieService.setExpirableCookie(ALERTS_CREATED_COOKIE_NAME, JSON.stringify(this.alertsCreated), 7);
    }

    exist(alertData: AlertData) {
        return this.alertsCreated.map((element) => JSON.stringify(element)).includes(JSON.stringify(alertData));
    }
}

interface AlertData {
    what: string,
    filters: PageDataFilters,
}