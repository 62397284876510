import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import {RedirectToUserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {TrovitUserSearchUrlParams} from "../../core/url/urlGenerator";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export class FiltersSubscriber {

    private readonly redirectSearch: RedirectToUserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        redirectSearch: RedirectToUserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.redirectSearch = redirectSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        const userSearchFilters = this.castToTrovitUserSearchUrlParams(pageData);
        document.querySelectorAll('.interlinking__link.facet:not(.selected)')
            .forEach((element: Element) => {
                const filterElement = element as HTMLElement;
                filterElement.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.redirectSearch.execute(
                        this.addSelectedFilterToUserSearchParams(filterElement, userSearchFilters, pageData)
                    );
                });
            });

        document.querySelectorAll('.js-filter-remove')
            .forEach((element: Element) => {
                const filterElement = element as HTMLElement;
                filterElement.addEventListener('click', (event) => {
                    event.preventDefault();
                    this.redirectSearch.execute(
                        this.removeSelectedFiltersFromUserSearchParams(filterElement, userSearchFilters, pageData)
                    );
                });
            });
    }

    private addSelectedFilterToUserSearchParams(
        filterElement: HTMLElement,
        userSearchFilters: TrovitUserSearchUrlParams,
        pageData: TrovitSerpData
    ): TrovitUserSearchUrlParams {
        const selectedGroup = filterElement.getAttribute('data-group')!!;
        const filterProperties = this.multipleFiltersMapping[selectedGroup];
        if (!filterProperties) return userSearchFilters;

        this.trackFilterWithGoogleAnalyticsService.execute({
            value: selectedGroup,
            isSem: pageData.isSem,
        } as TrackFilterWithGoogleAnalyticsData);

        filterProperties.forEach((property) => {
            userSearchFilters = {
                ...userSearchFilters,
                [property.name]: filterElement.dataset[property.dataset]!!
            };
        });

        return userSearchFilters
    }

    private removeSelectedFiltersFromUserSearchParams(
        filterElement: HTMLElement,
        userSearchFilters: TrovitUserSearchUrlParams,
        pageData: TrovitSerpData
    ): TrovitUserSearchUrlParams {
        const selectedGroup = filterElement.getAttribute('data-group')!!;
        const filterProperties = this.multipleFiltersMapping[selectedGroup];
        if (!filterProperties) return userSearchFilters;

        this.trackFilterWithGoogleAnalyticsService.execute({
            value: selectedGroup,
            isSem: pageData.isSem,
        } as TrackFilterWithGoogleAnalyticsData);

        filterProperties.forEach((property) => {
            delete userSearchFilters[property.name as keyof TrovitUserSearchUrlParams];
        });
        return userSearchFilters
    }

    private get singleFilterMapping(): { [selectedGroup: string]: {name: string, dataset: string} } {
        return {
            cityArea: {name: 'cityArea', dataset: 'label'},
            city: {name: 'city', dataset: 'label'},
            region: {name: 'region', dataset: 'label'},
            cityAreaId: {name: 'cityAreaId', dataset: 'id'},
            cityId: {name: 'cityId', dataset: 'id'},
            regionId: {name: 'regionId', dataset: 'id'},
            propertyType: {name: 'propertyType', dataset: 'label'},
            roomMin: {name: 'roomsMin', dataset: 'label'},
            bathroomMin: {name: 'bathRoomsMin', dataset: 'label'},
            priceMin: {name: 'priceMin', dataset: 'label'},
            priceMax: {name: 'priceMax', dataset: 'label'},
            areaMin: {name: 'areaMin', dataset: 'label'},
            areaMax: {name: 'areaMax', dataset: 'label'},
        };
    }

    private get multipleFiltersMapping(): { [selectedGroup: string]: {name: string , dataset: string}[] } {
        return {
            cityAreas: [this.singleFilterMapping['cityArea'], this.singleFilterMapping['cityAreaId']],
            cities: [this.singleFilterMapping['city'], this.singleFilterMapping['cityId']],
            regions: [this.singleFilterMapping['region'], this.singleFilterMapping['regionId']],
            propertyType: [this.singleFilterMapping['propertyType']],
            rooms: [this.singleFilterMapping['roomMin']],
            bathrooms: [this.singleFilterMapping['bathroomMin']],
            prices: [this.singleFilterMapping['priceMin'], this.singleFilterMapping['priceMax']],
            areas: [this.singleFilterMapping['areaMin'], this.singleFilterMapping['areaMax']],
        };
    }

    private castToTrovitUserSearchUrlParams(pageData: TrovitSerpData): TrovitUserSearchUrlParams {
        return TrovitSerpDataImpl.createWithDefaults(pageData, location.getOrigin())
            .toTrovitUserSearchUrlParams();
    }
}
