const fixedClassName = 'fixed';

export default class Sticky {
    private stickies: HTMLElement[];
    private initialTopStickies: Map<HTMLElement, number>;

    constructor() {
        this.stickies = [];
        this.initialTopStickies = new Map();
    }

    init() {
        this.stickies = Array.from(document.getElementsByClassName('wl-sticky')) as HTMLElement[];
        this.setInitialTopStickiesForNoFixed();
        if (this.stickies.length > 0) {
            this.registerScrollEvent();
            this.checkStickies();
        }
    }

    private setInitialTopStickiesForNoFixed() {
        this.stickies
            .filter((sticky) => !sticky.classList.contains(fixedClassName))
            .forEach((sticky) => this.initialTopStickies.set(sticky, sticky.offsetTop));
    }

    private registerScrollEvent() {
        window.addEventListener('scroll', this.checkStickies);
    }

    private checkStickies = () => {
        const scrolledPixels = window.scrollY;
        this.stickies.forEach((sticky) => {
            const stickyTop = this.getStickyTop(sticky)
            if (scrolledPixels > this.initialTopStickies.get(sticky)!! - stickyTop) {
                sticky.classList.add(fixedClassName);
                this.setInitialTopStickiesForNoFixed();
                if (sticky.hasAttribute("data-sticky-container-bottom-limit")) {
                    const stickyBoundingClientRect = sticky.getBoundingClientRect();
                    const bottomLimit = this.getBottomLimit(sticky)
                    sticky.style.marginTop = "0";
                    if (stickyBoundingClientRect.bottom > bottomLimit) {
                        sticky.style.top = (bottomLimit - stickyBoundingClientRect.height) + "px";
                    } else {
                        sticky.style.top = stickyTop + "px";
                    }
                } else {
                    sticky.style.top = stickyTop + "px";
                    sticky.style.marginTop = "initial";
                }
            } else {
                sticky.classList.remove(fixedClassName)
                sticky.style.top = "initial";
                // this.setInitialTopStickiesForNoFixed();
            }
        });
    }

    private getBottomLimit(sticky: HTMLElement) {
        let bottomLimitElement = document.querySelector(sticky.getAttribute("data-sticky-container-bottom-limit")!!);
        if (bottomLimitElement) {
            return bottomLimitElement.getBoundingClientRect().bottom;
        } else {
            return Number.MAX_VALUE;
        }
    }

    private getStickyTop(sticky: HTMLElement) {
        if (sticky.hasAttribute("data-sticky-top")) {
            return parseInt(sticky.getAttribute("data-sticky-top")!!);
        } else {
            return 0;
        }
    }
}