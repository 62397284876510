import {ConsentCookieIsAlreadySetEvent} from "../../../../common/ts/events/Events";
import CookieService from "../../../../common/ts/services/CookieService";
import {CONSENT_COOKIE_NAME} from "../../../../common/ts/components/core/ConsentCookie";
import {EventBus} from "../../../../common/ts/events/EventBus";

const acceptCookieDisclaimerButton = document.querySelector('#accept-cookie-disclaimer-button');
const denyCookieDisclaimerButton = document.querySelector('#deny-cookie-disclaimer-button');
const gdprCookieDisclaimer = document.querySelector('#gdpr-cookie-disclaimer');
export const TROVIT_PERSONALIZED_GOOGLE_ADS = "trovit_personalized_google_ads";

function handleCookieDisclaimerButtonClick(acceptCookieDisclaimer: string) {
    new CookieService().setExpirableCookie(CONSENT_COOKIE_NAME, '0', 365);
    new CookieService().setExpirableCookie(TROVIT_PERSONALIZED_GOOGLE_ADS, acceptCookieDisclaimer, 365);
    const eventBus: EventBus = EventBus.getInstance();
    eventBus.emit(new ConsentCookieIsAlreadySetEvent());
    (gdprCookieDisclaimer as HTMLElement).style.display = 'none';
}

const cookieService = new CookieService();
if (!cookieService.getCookie(CONSENT_COOKIE_NAME)) {
    if (gdprCookieDisclaimer) {
        (gdprCookieDisclaimer as HTMLElement).style.display = 'flex';
    }
}

if (acceptCookieDisclaimerButton) {
    acceptCookieDisclaimerButton.addEventListener('click', () => handleCookieDisclaimerButtonClick('1'));
}

if (denyCookieDisclaimerButton) {
    denyCookieDisclaimerButton.addEventListener('click', () => handleCookieDisclaimerButtonClick('0'));
}