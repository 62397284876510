interface ServiceDictionary<T extends (...args: any[]) => any> {
    [key: string]: T;
}

class ServiceContainer {
    private readonly services: ServiceDictionary<any> = {};

    constructor(services: any = null) {
        this.services = services;
    }

    public set(serviceName: string, service: any) {
        this.services[serviceName] = service;
    }

    public get(serviceName: string) {
        if (!this.services.hasOwnProperty(serviceName)) {
            throw new Error("Service " + serviceName + " does not exist in the container");
        }

        return this.services[serviceName]();
    }
}

export default ServiceContainer;
