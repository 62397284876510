import UserContactDataStorageService from "../../../../../../common/ts/services/UserContactDataStorageService";
import {SEND_ALERT_CREATION_EVENT_TYPE} from "./PointsOfCapture";
import {EventBus} from "../../../../../../common/ts/events/EventBus";
import {GoogleOneTapSignInEvent} from "../../../../../../common/ts/events/Events";

export const ALERT_CREATED_EVENT_TYPE = 'sentAlertCreation';
export const CLOSE_BUTTON_CLICKED_EVENT_TYPE = 'closeButtonClicked';

export class PointOfCaptureFormContainer {
    private container: HTMLElement | null;
    private content: HTMLElement | null;
    private form: HTMLFormElement | null;
    private inputEmail: HTMLInputElement | null;
    private storage: UserContactDataStorageService;
    private eventBus: EventBus;

    constructor(storage: UserContactDataStorageService, eventBus: EventBus) {
        this.container = null;
        this.content = null;
        this.form = null;
        this.inputEmail = null;
        this.storage = storage;
        this.eventBus = eventBus;
    }

    init(container: HTMLElement) {
        this.container = container;
        if (this.container) {
            this.content = this.getContent();
            if (this.isContentVisible()) {
                this.form = this.container.querySelector('form')
                this.inputEmail = this.container.querySelector("input[name='email']")
                if (this.form && this.inputEmail) {
                    this.fillFormFields();
                    this.registerEvents();
                }
            }
        }
    }

    isContentVisible() {
        try {
            return this.content && this.content.computedStyleMap().get('display') != 'none'
        } catch (e) {
            //This is because firefox throw an error with computedStyleMap
            return false;
        }
    }

    getVisibleSection() {
        let submitButtons = this.form!!.querySelectorAll('button[type="submit"]');
        let visibleButtons = Array.from(submitButtons).filter((button) => {return button.checkVisibility()});
        if (visibleButtons.length === 0) {
            return;
        }
        return parseInt((visibleButtons[0] as HTMLButtonElement).value);
    }

    private getContent() {
        let content = this.container!!.querySelector(".js-email-for-retention");
        if (content) {
            return content as HTMLElement;
        } else {
            return this.container!!;
        }
    }

    private fillFormFields() {
        this.storage.fillInputsWithStoredValues({input: this.inputEmail!!, type: 'email'});
        if (this.inputEmail!!.value != '') {
            this.inputEmail?.classList.add('filled');
        }
    }

    private registerEvents() {
        this.registerSubmitFormEvent();
        this.registerCloseButtonEvent();
        this.registerGoogleOneTapSignInEvent();
    }

    private registerSubmitFormEvent() {
        this.form!!.addEventListener('submit', this.submit)
    }

    private registerGoogleOneTapSignInEvent() {
        this.eventBus.subscribeWithId(
            GoogleOneTapSignInEvent.TYPE,
            {
                id: `${GoogleOneTapSignInEvent.TYPE}-handler-${this.container?.id}`,
                callback: () => { this.fillFormFields() },
            }
        )
    }

    private submit = (event: SubmitEvent) => {
        event.stopPropagation();
        event.preventDefault();
        let sectionId = parseInt((event.submitter!! as HTMLButtonElement).value);

        document.dispatchEvent(
            new CustomEvent(SEND_ALERT_CREATION_EVENT_TYPE,
                {
                    detail: {
                        'section': sectionId,
                        'email': this.inputEmail!!.value,
                        'object': this,
                    }
                }
            )
        );
    }

    applyFocusToInput() {
        this.inputEmail?.focus();
    }

    showError(feedBackMessage: string) {
        this.content!!.querySelector('.js-email-for-retention__feedback')!!.innerHTML = feedBackMessage;
        this.content?.classList.add('error');
    }

    showSuccess(feedBackMessage: string) {
        this.content!!.querySelector('.js-email-for-retention__feedback')!!.innerHTML = feedBackMessage;
        this.content?.classList.remove('error');
        this.content?.classList.add('success');
        document.dispatchEvent(
            new CustomEvent(ALERT_CREATED_EVENT_TYPE,
                {
                    detail: {
                        'object': this,
                    }
                }
            )
        );
    }

    private registerCloseButtonEvent() {
        this.form!!.querySelector('.js-close-button')!!.addEventListener('click', this.dispatchCloseButtonEvent);
    }

    private dispatchCloseButtonEvent = () => {
        document.dispatchEvent(
            new CustomEvent(CLOSE_BUTTON_CLICKED_EVENT_TYPE,
                {
                    detail: {
                        'object': this,
                    }
                }
            )
        );
    }
}