import location from "../../../../common/ts/core/location";
import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import {TrovitPageData, TrovitSerpDataImpl} from "../TrovitPageData";
import {ClearFiltersForm} from "./ClearAllFormFiltersSubscriber";

const SEARCH_BOX_FORM_SELECTOR: string = ".js-search-box";
const SEARCH_BOX_INPUT_SELECTOR: string = ".js-search-box-input";

export default class SearchBoxSubscriber {
    private userSearch: UserSearch;

    constructor(userSearch: UserSearch) {
        this.userSearch = userSearch
    }

    init(pageData: TrovitPageData) {
        const formElements = document.querySelectorAll(SEARCH_BOX_FORM_SELECTOR);
        formElements.forEach((formElement: Element) => {
            const formElementHtmlElement = formElement as HTMLInputElement
            formElementHtmlElement?.addEventListener('submit', (event) => {
                event.preventDefault();
                event.stopPropagation();
                const what: string = (formElementHtmlElement.querySelector(SEARCH_BOX_INPUT_SELECTOR) as HTMLInputElement)
                    ?.value.toLowerCase() as string;
                this.goToUserSearch(what, pageData);
                new ClearFiltersForm().clear()
            });
        });
    }

    private goToUserSearch(what: string, pageData: TrovitPageData) {
        const propertiesToClear = [
            'areaMin',
            'areaMax',
            'roomsMin',
            'bathRoomsMin',
            'geoId',
            'priceMin',
            'priceMax',
            'city',
            'cityArea',
            'region',
            'propertyType'
        ];

        propertiesToClear.forEach(property => {
            (pageData as any)[property] = undefined;
        });
        pageData.what = what
        this.userSearch.execute(
            TrovitSerpDataImpl.createWithDefaults(
                pageData,
                location.getOrigin()
            ).toTrovitUserSearchUrlParams()
        );
    }
}