import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class AreaRangeSelectorSubscriber {

    private readonly eventBus: EventBus
    private readonly userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-area-range-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;

                if (payload.id === "area-min-selector" || payload.id === "area-max-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: 'area',
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData);

                    const filterProperty = this.filterMapping[payload.id];
                    if (!filterProperty) return;
                    pageData[filterProperty] = payload.value;
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.userSearch.execute({
                        ...userSearchFilters,
                        [filterProperty]: payload.value
                    });
                }
            }
        });
    }

    private get filterMapping(): { [key: string]: string } {
        return {
            "area-min-selector": 'areaMin',
            "area-max-selector": 'areaMax'
        };
    }
}