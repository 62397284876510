import {EventBus} from "../../../../common/ts/events/EventBus";
import {ShowGoogleOneTapEvent} from "../../../../common/ts/events/Events";
import {TrovitSerpData} from "../TrovitPageData";
import AdFormService from "../../../../common/ts/services/AdFormService";
import { AlertCreationSections } from "../../core/AlertCreationSections";
import {PageDataFiltersGenerator} from "../components/retention/pointsOfCapture/PointsOfCapture";

export class GoogleOneTapSubscriber {

    private readonly eventBus: EventBus;
    private readonly adFormService: AdFormService;

    constructor(eventBus: EventBus, adFormService: AdFormService,) {
        this.eventBus = eventBus;
        this.adFormService = adFormService;
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(ShowGoogleOneTapEvent.TYPE, {
            id: 'trovit-serp-track-impression-for-google-one-tap',
            callback: () => this.trackAlertCreationImpression(pageData)
        });
    }

    private trackAlertCreationImpression(pageData: TrovitSerpData) {
        this.adFormService.alertCreationImpression(pageData,
            AlertCreationSections.ALERT_CREATION_SECTION_GOOGLEONE_TAP_GOOGLE_POPUP,
            PageDataFiltersGenerator.generateFrom(pageData),
        );
    }
}