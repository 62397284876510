import makeUserSearchUrlForStaticFilters, {TrovitUserSearchUrlParams} from "../urlGenerator";
import FeatureFlags, {TROVIT_USER_SEARCH_FEATURE_FLAG} from "../../../../../common/ts/FeatureFlags";
import {UserSearch} from "./RedirectToUserSearch";
import {EventBus} from "../../../../../common/ts/events/EventBus";
import {WhitelabelEventWithPayload} from "../../../../../common/ts/events/Events";

export class WindowService {
    updateURLWithoutPageReload(url: string) {
        window.history.pushState(null, '', url);
    }
}

export interface ListingsFilteringResponseEventPayload {
    numberOfFilteredResults: string;
}

export class ListingsFilteringResponseEvent implements WhitelabelEventWithPayload<ListingsFilteringResponseEventPayload> {
    type: string;
    payload: ListingsFilteringResponseEventPayload;
    static readonly TYPE: string = 'trovit-listing-filtering-response';

    constructor(payload: ListingsFilteringResponseEventPayload) {
        this.type = ListingsFilteringResponseEvent.TYPE;
        this.payload = payload;
    }
}

export interface ModalBlock {
    blockFullscreenModal(): void;

    unblockFullscreenModal(): void;
}

export class FullScreenModalBlock implements ModalBlock {
    private readonly modalName;

    constructor(modalName: string) {
        this.modalName = modalName;
    }

    blockFullscreenModal = () => {
        const modal = document.getElementById(this.modalName);
        if (modal) {
            modal.style.display = 'flex';
        }
    };

    unblockFullscreenModal = () => {
        const modal = document.getElementById(this.modalName);
        if (modal) {
            modal.style.display = 'none';
        }
    };
}

export class GetNumberOfListingsAjax implements UserSearch {
    private readonly windowService: WindowService;
    private readonly eventBus: EventBus
    private readonly featureFlags: FeatureFlags;
    private readonly modalBlock: ModalBlock;

    constructor(
        windowService: WindowService,
        eventBus: EventBus,
        featureFlags: FeatureFlags,
        modalBlock: ModalBlock
    ) {
        this.windowService = windowService;
        this.eventBus = eventBus;
        this.featureFlags = featureFlags;
        this.modalBlock = modalBlock;
    }

    execute(params: TrovitUserSearchUrlParams): void {
        if (params.what === "") {
            return;
        }

        this.modalBlock.blockFullscreenModal();

        this.windowService.updateURLWithoutPageReload(
            makeUserSearchUrlForStaticFilters(params, this.isWLUserSearchEnabled())
        );

        fetch('/wl/api/v1/filter', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(this.toListingFilteringRequest(params)),
        }).then(async (response) => {
                if (response.ok) {
                    this.eventBus.emit(
                        new ListingsFilteringResponseEvent(
                            JSON.parse(await response.text()) as ListingsFilteringResponseEventPayload
                        )
                    )
                }
                this.modalBlock.unblockFullscreenModal();
            }
        );
    }

    private toListingFilteringRequest(params: TrovitUserSearchUrlParams): object {
        return {
            areaMax: params.areaMax,
            areaMin: params.areaMin,
            bathRoomsMin: params.bathRoomsMin,
            cityArea: params.cityArea,
            city: params.city,
            region: params.region,
            cityAreaId: params.cityAreaId,
            cityId: params.cityId,
            regionId: params.regionId,
            geoId: params.geoId,
            priceMax: params.priceMax,
            priceMin: params.priceMin,
            propertyType: params.propertyType,
            roomsMin: params.roomsMin,
            typeId: params.typeId,
            what: params.what,
        }
    }

    private isWLUserSearchEnabled(): boolean {
        return this.featureFlags.isActiveFeature(TROVIT_USER_SEARCH_FEATURE_FLAG);
    }
}