import {EventBus} from "../../../../common/ts/events/EventBus";
import {ExtendedDescriptionClickedEvent, WhitelabelEvent} from "../../../../common/ts/events/Events";
import {VisitedCookie} from "../VisitedCookie";

export class ListingVisited {

    private readonly eventBus: EventBus;
    private readonly visitedCookie: VisitedCookie;

    constructor(eventBus: EventBus, visitedCookie: VisitedCookie,) {
        this.eventBus = eventBus;
        this.visitedCookie = visitedCookie;
    }

    init() {
        this.markAlreadyVisitedListings();
        document.querySelectorAll('.extended-description a')
            .forEach((anchor) => addSnippetClickedEventListener(anchor, this.eventBus));
        this.eventBus.subscribeWithId(ExtendedDescriptionClickedEvent.TYPE, {
            id: 'trovit-serp-listing-visited-subscriber',
            callback: this.onExtendedDescriptionClickedEvent
        });
    }

    private onExtendedDescriptionClickedEvent = (event: WhitelabelEvent) => {
        const {payload}: ExtendedDescriptionClickedEvent = event as ExtendedDescriptionClickedEvent;
        const listingId: string = payload.listingId;
        const articleEl = payload.listing;
        const isSponsored = payload.isSponsored;
        this.visitedCookie.addVisitedListing({
            id: listingId,
            isPremium: isSponsored,
            modalHasBeenSeen: false
        });
        this.addVisitedClassToArticle(articleEl);
    };

    private markAlreadyVisitedListings() {
        this.visitedCookie.getVisitedListings()
            .forEach((visitedListing) => {
                this.addVisitedClassToArticle(
                    document.querySelector(`.snippet-listing[data-id="${visitedListing.id}"]`)
                );
            });
    }

    private addVisitedClassToArticle(articleEl: Element | null) {
        if (articleEl) {
            articleEl.classList.add('visited');
        }
    }
}

export const addSnippetClickedEventListener = (anchor: Element, eventBus: EventBus) => {
    anchor.addEventListener('click', (event) => {
        const articleEl = anchor.closest('.snippet-listing')!!;
        const listingId: string = articleEl.getAttribute('data-id') || '';
        const isSponsored: boolean = articleEl.getAttribute('data-is-sponsored') === 'true';
        eventBus.emit(new ExtendedDescriptionClickedEvent({
            listingId,
            isSponsored,
            event,
            listing: articleEl
        }))
    });
}