import CookieService from "../../services/CookieService";

// Add const with name of the cookie
export const DIDOMI_CONSENT_COOKIE_NAME = "euconsent-v2";
export const CONSENT_COOKIE_NAME = "trovit_show_gdpr";


export function isDidomiConsentCookieSet() {
    return new CookieService().getCookie(DIDOMI_CONSENT_COOKIE_NAME) != undefined;
}

export function isConsentCookieSet() {
    return new CookieService().getCookie(CONSENT_COOKIE_NAME) != undefined;
}

