import PageData from '../PageData';
import UserData from "../UserData";
import {Section} from './Section';
import GA4Tracking from "../core/tracking/GA4Tracking";
import {TrovitPageData} from "../../../trovit/ts/serp/TrovitPageData";
import {PageDataFilters} from "../../../trovit/ts/serp/components/retention/pointsOfCapture/PointsOfCapture";
import DateFormatter from "./DateFormatter";
import {PermissionSendCommunicationsTracking} from "../core/tracking/PermissionSendCommunicationsTracking";

export enum ApplyOperationType {
    SALE = "SALE",
    RENT = "RENT"
}

export interface ApplyProjectPayload {
    pageViewId: string;
    projectId: string;
    userEmail: string;
    userName: string;
    userPhone: string;
    message: string;
    section: string;
    operationType?: ApplyOperationType | null;
    rentStartDate?: Date | null,
    rentEndDate?: Date | null,
}

export interface ApplyImpressionData {
    pageViewId: string;
    propertyAdId: string;
    section: Section;
    step: number;
    isProject: boolean;
}

export interface ApplyPhoneData {
    pageViewId: string;
    propertyAdId: string;
    section: Section;
    userPhone: string;
}

export interface ApplyImpressionDisplayPhoneData {
    pageViewId: string;
    propertyAdId: string;
    section: Section;
    step: number;
}

export interface ListingImpressionData {
    pageViewId: string;
    propertyAdIds: string[];
    section: Section;
}

export interface LeadContactData {
    pageViewId: string;
    propertyAdId: string;
    userName: string;
    userEmail: string;
    userPhone: string;
    message: string;
    section?: Section | null;
    operationType?: ApplyOperationType | null;
    rentStartDate?: Date | null,
    rentEndDate?: Date | null,
    scheduleDate?: string | null, // YYYY-MM-DD
    scheduleTime?: string | null, // HH:mm
    cpcClickAttribution?: string | null
}

export interface FormExtraData {
    rentStartDate?: Date | null;
    rentEndDate?: Date | null;
    message?: string | null;
    trackPermissionSendCommunications?: boolean;
}

export interface MultiEnquirySimilarsRequest {
    id: string;
    sectionId?: number
}

class AdFormService {

    private addLocaleHeader(): HeadersInit {
        const meta = document.querySelector('meta[name="wl-locale"]');
        if (!meta) return {};
        return {
            'wl-locale': meta.getAttribute('content') || '',
        }
    }

    private formatDate(date?: Date | null) {
        return DateFormatter.formatDate(date);
    }

    async applyContactFormSent(pageData: PageData,
                               userName: string,
                               userEmail: string,
                               userPhone: string,
                               message: string,
                               section?: string,
                               permissionSendCommunications?: string | null,
                               operationType?: ApplyOperationType | null,
                               rentStartDate?: Date | null,
                               rentEndDate?: Date | null,
    ) {
        const data: any = {
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            userName,
            userEmail,
            userPhone,
            message,
            section,
            operationType,
            rentStartDate: this.formatDate(rentStartDate),
            rentEndDate: this.formatDate(rentEndDate),
            permissionSendCommunications,
        };

        if (pageData.cpcClickAttribution != undefined) {
            data.cpcClickAttribution = pageData.cpcClickAttribution
        }

        return await fetch('/adform/api/lead-contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify(data),
        });
    };

    async leadContactApply(leadContactData: LeadContactData) {
        let data: any
        if (leadContactData.operationType == ApplyOperationType.RENT) {
            data = {
                ...leadContactData,
                rentStartDate: this.formatDate(leadContactData.rentStartDate),
                rentEndDate: this.formatDate(leadContactData.rentEndDate)
            };
        } else {
            data = {
                ...leadContactData,
            };
        }

        if (leadContactData.cpcClickAttribution != undefined) {
            data.cpcClickAttribution = leadContactData.cpcClickAttribution
        }

        return fetch('/adform/api/lead-contact', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify(data),
        });
    };

    applyImpression = (pageData: PageData, section: Section, step: number) => fetch('/adform/api/events/apply-impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            section,
            step,
        }),
    });

    sendApplyImpression = (applyImpressionData: ApplyImpressionData) => {
        let url = '/adform/api/events/apply-impression';
        if (applyImpressionData.isProject) {
            url = '/api/v1/projects/apply-impression';
        }
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify(applyImpressionData),
        });
    };

    applyImpressionChatOptionDisplay = (pageData: PageData, section: string, step: number) => fetch('/adform/api/events/apply-impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            section,
            step,
        }),
    });

    applyImpressionDisplayPhone = (applyImpressionDisplayPhoneData: ApplyImpressionDisplayPhoneData) => fetch('/adform/api/events/apply-impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify(applyImpressionDisplayPhoneData),
    });

    applyImpressionProjectListings = (pageData: PageData, operation: number, step: number) => fetch('/adform/api/events/apply-impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            section: operation == 1 ? 'SECTION_SHOWCASE_ADS_SALE' : 'SECTION_SHOWCASE_ADS_RENT',
            step,
        }),
    });

    applyChatOptionDisplay = (pageData: PageData, section: string, userData: UserData, formExtraData?: FormExtraData) => {
        const data: any = {
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            section: section
        };
        if (userData.name) data.userName = userData.name;
        if (userData.email) data.userEmail = userData.email;
        if (userData.phone) data.userPhone = userData.phone;
        if (pageData.cpcClickAttribution != undefined) {
            data.cpcClickAttribution = pageData.cpcClickAttribution
        }
        if (formExtraData) {
            data.rentStartDate = this.formatDate(formExtraData.rentStartDate);
            data.rentEndDate = this.formatDate(formExtraData.rentEndDate);
            data.message = formExtraData.message;
        }
        const trackPermissionSendCommunications: boolean = formExtraData?.trackPermissionSendCommunications === undefined ? true : formExtraData.trackPermissionSendCommunications;
        if (trackPermissionSendCommunications) {
            data.permissionSendCommunications = this.retrievePermissionPersonalAssistant(pageData, document.querySelector('.js-chat-options-form')! as HTMLElement);
        }

        return fetch('/adform/api/events/apply', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify(data),
        })
    };

    private retrievePermissionPersonalAssistant(pageData: PageData, element: HTMLElement) {
        let permissionSendCommunications = new PermissionSendCommunicationsTracking();
        if (permissionSendCommunications) {
            permissionSendCommunications.emitPermissionSendCommunicationsEvent(pageData, element);
            return permissionSendCommunications.getPermissionSendCommunications(element)
        }
        return null
    }

    applyPhone = (applyPhoneData: ApplyPhoneData) => {
        return fetch('/adform/api/events/apply', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify(applyPhoneData),
        })
    };

    applyEvent = (pageData: PageData, section: Section, userData: UserData, formExtraData?: FormExtraData, mustSendPersonalAssistantCheck: Boolean = false) => {
        const data: any = {
            pageViewId: pageData.pageViewId,
            propertyAdId: pageData.id,
            section: section
        };
        if (userData.name) data.userName = userData.name;
        if (userData.email) data.userEmail = userData.email;
        if (userData.phone) data.userPhone = userData.phone;
        if (pageData.cpcClickAttribution != undefined) {
            data.cpcClickAttribution = pageData.cpcClickAttribution
        }
        if (formExtraData) {
            data.rentStartDate = this.formatDate(formExtraData.rentStartDate);
            data.rentEndDate = this.formatDate(formExtraData.rentEndDate);
            data.message = formExtraData.message;
        }

        if (section === Section.SECTION_ADPAGE_TOP_CALLME) {
            GA4Tracking.trackEvent("detail-page-apply-request-call");
        } else {
            GA4Tracking.trackEvent("detail-page-apply-view-phone");
        }
        if (mustSendPersonalAssistantCheck) {
            data.permissionSendCommunications = this.retrievePermissionPersonalAssistant(pageData, document.getElementById('view-phone-contact-form')!);
        }

        return fetch('/adform/api/events/apply', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify(data),
        })
    }

    impression = (pageViewId: string, section: Section, propertyAdIds: String[]) => fetch('/adform/api/events/impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageViewId,
            propertyAdIds: propertyAdIds,
            section: section,
            step: 1,
        }),
    });

    listingImpression = (listingImpressionData: ListingImpressionData) => fetch('/adform/api/events/impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: listingImpressionData.pageViewId,
            propertyAdIds: listingImpressionData.propertyAdIds,
            section: listingImpressionData.section,
            step: 1,
        }),
    });

    impressionProjectLocationMap = (pageViewId: string, id: string) => fetch('/adform/api/events/impression-project', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageViewId,
            projectId: id,
            section: 'SECTION_ADPAGE_MAP',
            operationType: 1,
        }),
    });

    impressionProjectListings = (pageData: PageData, operation: number) => fetch('/adform/api/events/impression-project', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageData.pageViewId,
            projectId: pageData.id,
            section: operation == 1 ? 'SECTION_SHOWCASE_ADS_SALE' : 'SECTION_SHOWCASE_ADS_RENT',
            operationType: operation,
        }),
    });

    async applyProject(data: ApplyProjectPayload) {
        return fetch('/api/v1/projects/apply', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify({
                    ...data,
                    rentStartDate: this.formatDate(data.rentStartDate),
                    rentEndDate: this.formatDate(data.rentEndDate)
                },
            ),
        });
    };

    alertCreationImpression = (pageData: TrovitPageData, section: number, filters: PageDataFilters) => fetch('/adform/api/events/alert-creation-impression', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pageViewId: pageData.pageViewId,
            alertCreationSection: section,
            operationType: pageData.operationType,
            page: pageData.page,
            what: pageData.what,
            fallbackNoResult: false,
            filters: filters,
        }),
    });

    wpnSubscriber = (
        pageData: TrovitPageData,
        subscription : PushSubscription,
        pushUserId: string,
        uqTrovitId: string,
    ) => fetch('/wl/api/v1/notifications/wpn/subscription', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pushUserId: pushUserId,
            endpoint: subscription.toJSON().endpoint,
            authKeys: subscription.toJSON().keys,
            what: pageData.what,
            uqTrovitId: uqTrovitId,
            pageViewId: pageData.pageViewId,
            typeId: pageData.typeId,
        }),
    });

    trackWPNPermissions = (
        pageData: TrovitPageData,
        pushUserId: String,
        uqTrovitId: String,
        eventType: String,
    ) => fetch('/adform/api/events/wpn-permissions', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            ...this.addLocaleHeader(),
        },
        body: JSON.stringify({
            pushUserId: pushUserId,
            what: pageData.what,
            uqTrovitId: uqTrovitId,
            pageViewId: pageData.pageViewId,
            typeId: pageData.typeId,
            eventType: eventType,
        }),
    });

    alertCreation = (pageData: TrovitPageData, section: number, email: string, pageDataFilters: PageDataFilters) => {
        let totalResults = 0;
        if (pageData.numberOfListings) {
            pageData.numberOfListings;
        }
        return fetch('/wl/api/v1/notifications/email/subscription', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
            body: JSON.stringify({
                email: email,
                trovit_type_id: pageData.typeId,
                operation_type: pageData.operationType,
                queries: [
                    {what: pageData.what}
                ],
                filters: pageDataFilters,
                context: {
                    section_id: section,
                    total_results: totalResults,
                },
                page_view_id: pageData.pageViewId,
            }),
        });
    };

    isProjectPage(pageViewType: string): boolean {
        return pageViewType === 'PROJECT_PAGE_DIRECTORY';
    }

    async retrieveMultiEnquirySimilars(data: MultiEnquirySimilarsRequest): Promise<string> {
        const sectionId: string = data.sectionId ? `?sectionId=${data.sectionId}` : '';
        return fetch(`/api/enquiry/${data.id}/similars${sectionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
        }).then(response => response.text());
    };

    async retrievePhoneMultiEnquirySimilars(data: MultiEnquirySimilarsRequest): Promise<string> {
        const sectionId: string = data.sectionId ? `?sectionId=${data.sectionId}` : '';
        return fetch(`/api/phone/enquiry/${data.id}/similars${sectionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
        }).then(response => response.text());
    };

    async retrieveWhatsAppMultiEnquirySimilars(data: MultiEnquirySimilarsRequest): Promise<string> {
        const sectionId: string = data.sectionId ? `?sectionId=${data.sectionId}` : '';
        return fetch(`/api/whatsapp/enquiry/${data.id}/similars${sectionId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json; charset=utf-8',
                ...this.addLocaleHeader(),
            },
        }).then(response => response.text());
    };
}

export default AdFormService;
