import TypesSelectorSubscriber from "../../serp/subscribers/TypesSelectorSubscriber";
import SearchBoxSubscriber from "../../serp/subscribers/SearchBoxSubscriber";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {PageLoadedEvent} from "../../../../common/ts/events/Events";
import {TrovitPageData} from "../../serp/TrovitPageData";
import {serviceContainer} from "../../serp/SerpServiceContainerFactory";
import {connect} from "../../../../common/ts/connect";

const eventBus: EventBus = EventBus.getInstance();

eventBus.subscribeWithId(
    PageLoadedEvent.TYPE,
    {
        id: 'trovit-header-page-loaded-subscriber',
        callback: (event) => {
            const pageData: TrovitPageData = (event as PageLoadedEvent<TrovitPageData>).payload;
            connect(() => {
                serviceContainer.get(TypesSelectorSubscriber.name)
                    .init(pageData);
                serviceContainer.get(SearchBoxSubscriber.name)
                    .init(pageData);
            });
        }
    }
);
