export const ALERT_BUTTON_CLICKED_EVENT_TYPE = "AlertButtonClicked";
export const SEARCH_SAVED_EVENT_TYPE = "SearchSaved";
export const WEB_PUSH_ALLOWED_EVENT_TYPE = "WebPushAllowed";
export const FILTERS_MODAL_OPENED_EVENT_TYPE = "FiltersModalOpened";
export const SECTION_CLICKED_EVENT_TYPE = "SectionClicked";
export const FILTERS_UPDATED_EVENT_TYPE = "FiltersUpdated";
export const MARKET_STATS_VIEWED_EVENT_TYPE = "MarketStatsViewed";
export const MARKET_STATS_CLICKED_EVENT_TYPE = "MarketStatsClicked";
export const MARKET_STATS_DROPDOWN_CLICKED_EVENT_TYPE = "MarketStatsDropdownClicked";
export const SHARE_CLICKED_EVENT_TYPE = "ShareClicked";
export const FAVORITE_ADDED_EVENT_TYPE = "FavoriteAdded";
export const FAVORITE_REMOVED_EVENT_TYPE = "FavoriteRemoved";
export const PERMISSION_SEND_COMMUNICATIONS = "PermissionSendCommunications"
export const LOWER_PRICE_NOTIFY_CLICKED_EVENT_TYPE = "LowerPriceNotifyClicked";

export interface AlertButtonClickedEventPayload {
    section?: string;
    sectionId?: string;
    sectionName?: string;
    eventType: string;
    serpUrl: string;
}
export interface SearchSavedEventPayload {
    section?: string;
    sectionId?: string;
    sectionName?: string;
    eventType: string;
    serpUrl: string;
}

export interface WebPushAllowedEventPayload {
    eventType: string;
    serpUrl: string;
    pageViewId?: string;
    section?: string;
}

export interface FiltersModalOpenedEventPayload {
    eventType: string;
    serpUrl: string;
}

export interface FiltersUpdatedEventPayload {
    eventType: string;
    serpUrl: string;
    [key: string]: string | null | undefined;
}

export interface SectionClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionName?: string;
    pageViewId: string;
    value?: string;
    sectionId?: string;
}

export interface MarketStatsViewedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    sectionName: string;
    pageViewId: string;
    operationType: string;
    propertyType: string;
    geoId: string;
}

export interface MarketStatsClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    sectionName: string;
    pageViewId: string;
    operationType: string;
    propertyType: string;
    geoId: string;
    rooms?: number;
    amenity?: string;
    alternativeGeoId?: string;
    stratum?: string;
}

export interface MarketStatsDropdownClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    sectionName: string;
    pageViewId: string;
    operationType: string;
    propertyType: string;
    geoId: string;
    previousGeoId?: string;
    selectedGeoId: string;
    previousPriceEvolutionType?: string;
    selectedPriceEvolutionType: string;
}

export interface ShareClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    pageViewId: string;
    listingId: string;
    sectionName: string;
    pageViewTypeId?: string;
}

export interface FavoriteClickedEventPayload {
    eventType: string;
    serpUrl: string;
    sectionId: string;
    pageViewId: string;
    listingId: string;
    sectionName: string;
    pageViewTypeId?: string;
}

export interface PermissionSendCommunicationsEventPayload {
    eventType: string;
    serpUrl: string;
    pageViewId: string;
    value: string;
}

export interface LowerPriceNotifyClickedEventPayload {
    eventType: string;
    serpUrl: string;
    pageViewId: string;
    value: string;
    listingId: string;
}
