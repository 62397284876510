import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class CitiesSelectorSubscriber {

    private readonly eventBus: EventBus
    private readonly userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-cities-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;
                if (payload.id === "cities-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: 'cities', // This value should match with the value used for Desktop in FiltersSubscriber.ts
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData)
                    const selectedCityId = parseInt(payload.value);
                    const selectCityElement = document.getElementById(`${payload.id}`) as HTMLSelectElement;
                    const selectedCityLabel = selectCityElement.options[selectCityElement.selectedIndex].text;
                    if (selectedCityId) {
                        pageData['city'] = selectedCityLabel
                        pageData['cityId'] = selectedCityId
                    } else {
                        delete pageData['city']
                        delete pageData['cityId']
                    }
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.userSearch.execute(userSearchFilters);
                }
            }
        });
    }
}