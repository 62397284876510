import UserContactDataStorageService from "../../../../../../common/ts/services/UserContactDataStorageService";
import {
    AlertCreatedPayload,
    AlertCreationImpressionPayload, PointOfCaptureClosedPayload, SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE,
    TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE
} from "./PointsOfCapture";
import {ALERT_CREATED_EVENT_TYPE, PointOfCaptureFormContainer} from "./PointOfCaptureFormContainer";
import {PointOfCapture} from "./CookieAlertsClosedRepository";
import {EventBus} from "../../../../../../common/ts/events/EventBus";

export class BottomPointOfCapture {
    private htmlElement: HTMLElement | null;
    private formContainer: PointOfCaptureFormContainer;
    private forceClose: boolean;
    private readonly _showStyleClass = 'show';

    constructor(eventBus: EventBus, storage: UserContactDataStorageService) {
        this.htmlElement = null;
        this.formContainer = new PointOfCaptureFormContainer(storage, eventBus);
        this.forceClose = false;
    }

    init(alertAlreadyCreated: boolean, alertAlreadyClosed: (pointOfCapture: PointOfCapture) => boolean) {
        this.htmlElement = document.getElementById("bottom-retention");
        if (alertAlreadyCreated || alertAlreadyClosed(PointOfCapture.BOTTOM)) {
            this.removeMe();
            return;
        }
        if (this.htmlElement && this.htmlElement.checkVisibility()) {
            this.formContainer.init(this.htmlElement);
            this.registerEvents();
            this.trackEvents();
        }
    }

    private registerEvents() {
        this.registerScrollEvent();
        this.registerCloseElementEvent();
        this.registerAlertCreatedEvent();
    }

    private registerScrollEvent() {
        window.addEventListener('scroll', this.checkElementVisibility);
    }

    private checkElementVisibility = () => {
        if (this.forceClose) {
            this.hideElement();
        } else {
            let documentHeight = document.body.scrollHeight;
            let currentScroll = window.scrollY + window.innerHeight;
            let pixelsToBottomToShown = 1500;
            if (currentScroll + pixelsToBottomToShown >= documentHeight) {
                this.showElement();
            } else if (currentScroll <= documentHeight - pixelsToBottomToShown) {
                this.hideElement();
            }
        }
    }

    private showElement() {
        this.htmlElement?.classList.add(this._showStyleClass);
    }

    private hideElement() {
        this.htmlElement?.classList.remove(this._showStyleClass);
    }

    private trackEvents() {
        let sectionId = this.formContainer.getVisibleSection();
        if (sectionId) {
            document.dispatchEvent(new CustomEvent<AlertCreationImpressionPayload>(TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE, {detail: {'section': sectionId}}));
        }
    }

    private registerCloseElementEvent() {
        // @ts-ignore
        this.htmlElement?.querySelector('.js-close').addEventListener('click', this.closeElement);
    }

    private closeElement = () => {
        this.forceClose = true;
        this.checkElementVisibility();
        this.dispatchPointOfCaptureClosed();
    }

    private dispatchPointOfCaptureClosed() {
        document.dispatchEvent(new CustomEvent<PointOfCaptureClosedPayload>(SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE, {detail: {'type': PointOfCapture.BOTTOM}}));
    }

    private registerAlertCreatedEvent() {
        // @ts-ignore
        document.addEventListener(ALERT_CREATED_EVENT_TYPE, this.alertCreated);
    }

    private alertCreated = (e: CustomEvent<AlertCreatedPayload>) => {
        setTimeout(this.removeMe, 3000);
    }

    private removeMe = () => {
        this.htmlElement?.remove();
    }
}