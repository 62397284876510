import dom from "../../../../common/ts/core/dom";
import {
    FullPageModalCloseEvent,
    FullPageModalOpenEvent,
} from "../../../../common/ts/events/Events";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import {RedirectToUserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {ModalBlock} from "../../core/url/services/GetNumberOfListingsAjax";
import AjaxFilteringActivated from "../../AjaxFilteringActivated";

const OPEN_FILTERS_MODAL_BUTTON_SELECTOR = ".js-open-filters-modal";
const CLOSE_FILTERS_MODAL_BUTTON_SELECTOR = ".js-close-filters-modal";

const isAjaxFilteringActivated = new AjaxFilteringActivated();

export default class FiltersModalSubscriber {
    private readonly MODAL_ID = 'filters-modal'
    private readonly eventBus: EventBus
    private readonly redirectToUserSearch: RedirectToUserSearch;
    private readonly modalBlock: ModalBlock;

    constructor(
        eventBus: EventBus,
        redirectToUserSearch: RedirectToUserSearch,
        modalBlock: ModalBlock
    ) {
        this.eventBus = eventBus;
        this.redirectToUserSearch = redirectToUserSearch;
        this.modalBlock = modalBlock;
    }

    init(pageData: TrovitSerpData) {
        const openFiltersModalButton = dom.select(OPEN_FILTERS_MODAL_BUTTON_SELECTOR);
        const closeFiltersModalButton = dom.select(CLOSE_FILTERS_MODAL_BUTTON_SELECTOR);
        openFiltersModalButton?.addEventListener('click', (_) => {
            this.eventBus.emit(new FullPageModalOpenEvent({
                id: this.MODAL_ID
            }))
        });

        closeFiltersModalButton?.addEventListener('click', (_) => {
            if (isAjaxFilteringActivated.isEnabled(pageData.device)) {
                this.modalBlock.blockFullscreenModal();
                this.redirectToUserSearch.execute(
                    TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams()
                );
            } else {
                this.eventBus.emit(new FullPageModalCloseEvent({
                    id: this.MODAL_ID
                }));
            }
        });
    }
}