import {WhitelabelEvent, WhitelabelEventWithPayload} from "../events/Events";
import {EventBus} from "../events/EventBus";

let globalObject: Window | object;

function getPathname(location: Location | URL = window.location): string {
   return location.pathname;
}

function getOrigin(location: Location | URL = window.location): string {
    return location.origin;
}

function getQueryParams(location: Location | URL = window.location): string {
    return location.search;
}

interface AssignUrlPayload {
    url: string,
}

class AssignUrlEvent implements WhitelabelEventWithPayload<AssignUrlPayload> {
    payload: AssignUrlPayload;
    type: string;
    static readonly TYPE: string = 'location-assign-request';

    constructor(payload: AssignUrlPayload) {
        this.type = AssignUrlEvent.TYPE;
        this.payload = payload;
    }
}

export function assignUrlEventHandler (event: WhitelabelEvent) {
    const { payload: { url }} = event as AssignUrlEvent;
    (globalObject as Window).location.assign(url);
}

function init(go: Location | object = window) {
    globalObject = go
    const eventBus: EventBus = EventBus.getInstance();
    eventBus.subscribe(AssignUrlEvent.TYPE, assignUrlEventHandler);
}

export default {
    getPathname,
    getOrigin,
    getQueryParams,
    AssignUrlEvent,
    init,
}