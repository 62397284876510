import {RedirectToUserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class SortBySelectorSubscriber {

    private readonly eventBus: EventBus
    private readonly redirectSearch: RedirectToUserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        redirectSearch: RedirectToUserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.redirectSearch = redirectSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-sort-by-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;

                if (payload.id === "sort-by-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        filterType: 'Sort',
                        value: payload.value,
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData);
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.redirectSearch.execute(
                        {
                            ...userSearchFilters,
                            sortBy: payload.value,
                        }
                    );
                }
            }
        });
    }

}