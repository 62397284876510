import location from "../../../../common/ts/core/location";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import {EventBus} from "../../../../common/ts/events/EventBus";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class RegionsSelectorSubscriber {

    private readonly eventBus: EventBus
    private readonly userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-regions-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;
                if (payload.id === "regions-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: 'regions', // This value should match with the value used for Desktop in FiltersSubscriber.ts
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData)
                    const selectedRegionId = parseInt(payload.value);
                    const selectRegionElement = document.getElementById(`${payload.id}`) as HTMLSelectElement;
                    const selectedRegionLabel = selectRegionElement.options[selectRegionElement.selectedIndex].text;
                    if (selectedRegionId) {
                        pageData['region'] = selectedRegionLabel
                        pageData['regionId'] = selectedRegionId
                    } else {
                        delete pageData['region']
                        delete pageData['regionId']
                    }
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.userSearch.execute(userSearchFilters);
                }
            }
        });
    }
}