import {EventBus} from "../../../../common/ts/events/EventBus";
import {
    SnippetViewExtendedDescriptionClickedEvent
} from "../../../../common/ts/events/Events";

export class ToggleDescription {
    private readonly eventBus: EventBus;

    constructor(eventBus: EventBus) {
        this.eventBus = eventBus;
    }

    init() {
        document.querySelectorAll('.js-show-extended-description')
            .forEach((element) => addExtendedDescriptionClickEventListener(element, this.eventBus));
        this.eventBus.subscribeWithId(SnippetViewExtendedDescriptionClickedEvent.TYPE, {
            id: 'trovit-serp-listings-view-extended-description-subscriber',
            callback: (event) => {
                const {payload}: SnippetViewExtendedDescriptionClickedEvent = event as SnippetViewExtendedDescriptionClickedEvent;
                const snippet = payload.element.closest('.snippet-listing');
                if (snippet) {
                    snippet.querySelectorAll('.toggle-description')
                        .forEach((toggleDescription: Element) => {
                            const toggleDescriptionEl = toggleDescription as HTMLElement;
                            const computedStyle = window.getComputedStyle(toggleDescriptionEl);
                            toggleDescriptionEl.style.display = computedStyle.display === 'none' ? 'flex' : 'none';
                        });
                }
            }
        });
    }
}

export const addExtendedDescriptionClickEventListener = (element: Element, eventBus: EventBus) => {
    element.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
        eventBus.emit(new SnippetViewExtendedDescriptionClickedEvent({element: element}))
    });
}



