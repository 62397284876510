import {RedirectToUrl} from "../RedirectToUrl";
import FeatureFlags, {TROVIT_USER_SEARCH_FEATURE_FLAG} from "../../../../../common/ts/FeatureFlags";
import makeUserSearchUrlForStaticFilters, {TrovitUserSearchUrlParams} from "../urlGenerator";

export interface UserSearch {
    execute(params: TrovitUserSearchUrlParams): void;
}

export class RedirectToUserSearch implements UserSearch {
    redirectToUrl: RedirectToUrl
    featureFlags: FeatureFlags

    constructor(
        redirectToUrl: RedirectToUrl,
        featureFlags: FeatureFlags
    ) {
        this.redirectToUrl = redirectToUrl;
        this.featureFlags = featureFlags;
    }

    execute(params: TrovitUserSearchUrlParams): void {
        if (params.what === "") {
            return;
        }

        this.redirectToUrl.goTo(
            makeUserSearchUrlForStaticFilters(params, this.isWLUserSearchEnabled()),
        );
    }

    private isWLUserSearchEnabled(): boolean {
        return new FeatureFlags().isActiveFeature(TROVIT_USER_SEARCH_FEATURE_FLAG);
    }
}