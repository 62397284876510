import {EventBus} from "../../../../common/ts/events/EventBus";
import {
    FullPageModalCloseEvent,
    FullPageModalOpenEvent, SerpListingsUpdatedEvent,
    SerpSnippetClickedEvent
} from "../../../../common/ts/events/Events";
import {VisitedCookie} from "../VisitedCookie";
import DuplicatedClickListingService from "../services/DuplicatedClickListingService";
import {TrovitSerpData} from "../TrovitPageData";

export class ListingsSubscriber {
    private eventBus: EventBus;
    private visitedCookie: VisitedCookie;
    private duplicateClickListingService: DuplicatedClickListingService;
    private sponsoredListingIds: Set<string>;
    private subscribedListingIds: Set<string>;

    constructor(
        eventBus: EventBus,
        visitedCookie: VisitedCookie,
        duplicateClickListingService: DuplicatedClickListingService
    ) {
        this.eventBus = eventBus;
        this.visitedCookie = visitedCookie;
        this.duplicateClickListingService = duplicateClickListingService;
        this.sponsoredListingIds = new Set<string>();
        this.subscribedListingIds = new Set<string>();
    }

    init(pageData: TrovitSerpData) {
        this.subscribeListingClick();

        this.eventBus.subscribeWithId(SerpSnippetClickedEvent.TYPE, {
            id: 'trovit-serp-snippet-clicked-event-duplicated-click-subscriber',
            callback: (event) => {
                const {payload}: SerpSnippetClickedEvent = event as SerpSnippetClickedEvent;
                const listingId: string = payload.listingId;
                if (this.visitedCookie.isVisited(listingId) && this.visitedCookie.theModalHasBeenSeen(listingId)) return;

                if (!this.visitedCookie.isVisited(listingId)) {
                    this.visitedCookie.addVisitedListing({
                        id: listingId,
                        isPremium: payload.isSponsored,
                        modalHasBeenSeen: false
                    });
                    payload.listing.classList.add('visited');
                } else {
                    if (pageData.isDirectCountry) return;
                    payload.event.preventDefault();
                    payload.event.stopPropagation();
                    this.duplicateClickListingService.execute({
                        listingId: listingId,
                        pageViewId: pageData.pageViewId,
                        type: pageData.typeId,
                        geoId: pageData.geoId,
                        excludedListingIds: [...this.sponsoredListingIds],
                        currentOrigin: pageData.currentOrigin
                    }).then((response) => {
                        if (!response || response.trim() === '') return;
                        const duplicatedClickListingModalIdSelector: string = 'duplicated-click-listing-modal';
                        const link: HTMLAnchorElement = document.querySelector(`#${duplicatedClickListingModalIdSelector} .js-duplicate-link`)!!;
                        link.href = (payload.listing.querySelector(".js-listing") as HTMLAnchorElement).href;
                        document.querySelector(`#${duplicatedClickListingModalIdSelector} .js-duplicate-listings`)!!.innerHTML = response;
                        this.eventBus.emit(new FullPageModalOpenEvent({
                            id: duplicatedClickListingModalIdSelector,
                        }))
                        const closeText: HTMLDivElement = document.querySelector(`#${duplicatedClickListingModalIdSelector} .js-duplicate-close`)!!;
                        closeText.addEventListener('click', () => {
                            this.eventBus.emit(new FullPageModalCloseEvent({
                                id: duplicatedClickListingModalIdSelector
                            }))
                        });
                    });
                    this.visitedCookie.setModalHasBeenSeen(listingId);
                }
            }
        });
        this.eventBus.subscribeWithId(SerpListingsUpdatedEvent.TYPE, {
            id: 'trovit-serp-listings-updated-subscriber',
            callback: () => {
                this.subscribeListingClick();
            }
        });
    }

    private subscribeListingClick() {
        document.querySelectorAll('.js-listing')
            .forEach((element: Element) => {
                const listingId: string = element.parentElement!!.getAttribute('data-id') || '';
                const isSponsored: boolean = element.parentElement!!.getAttribute('data-is-sponsored') === 'true';
                if (isSponsored) {
                    this.sponsoredListingIds.add(listingId);
                }
                if (this.subscribedListingIds.has(listingId)) return;
                element.addEventListener('click', (event) => {
                    if (!(event.target as HTMLElement).dataset.swiperButton) {
                        this.eventBus.emit(new SerpSnippetClickedEvent({
                            listingId,
                            isSponsored,
                            listing: element.parentElement!!,
                            event
                        }))
                    }
                });
                this.subscribedListingIds.add(listingId);
            });
    }
}