import {EventBus} from "../../../../common/ts/events/EventBus";
import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import {LinearSelectorClickEvent} from "../../../../common/ts/events/Events";
import location from "../../../../common/ts/core/location";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class BedroomsLinearSelectorFilterSubscriber {
    private eventBus: EventBus
    private userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService


    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(LinearSelectorClickEvent.TYPE, {
            id: 'trovit-serp-bedrooms-linear-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: LinearSelectorClickEvent = event as LinearSelectorClickEvent;

                if (payload.inputName === "minBedrooms") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: payload.inputName,
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData);
                    const roomsMin: string = payload.inputChecked ? payload.inputValue : ''
                    pageData.roomsMin = roomsMin;
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.userSearch.execute({
                        ...userSearchFilters,
                        roomsMin: roomsMin,
                    });
                }
            }
        });
    }
}