import {TrovitType} from "../core/TrovitType";
import {TrovitUserSearchUrlParams} from "../core/url/urlGenerator";

export interface TrovitPageData {
    what: string;
    pageViewId: string;
    typeId: TrovitType;
    device: string;
    isSem: boolean;
    currentOrigin: number;
    operationType: string;
    page: number;
    numberOfListings: number;
    isRolledOutCountry: boolean;
    isDirectCountry: boolean;
    userHasInteracted: boolean;
}

export interface TrovitSerpData extends TrovitPageData {
    [key: string]: any;
    geoId?: string;
    roomsMin?: string;
    bathRoomsMin?: string;
    priceMin?: string;
    priceMax?: string;
    cityArea?: string;
    city?: string;
    region?: string;
    regionId?: number;
    cityId?: number;
    cityAreaId?: number;
    propertyType?: string;
    areaMin?: number;
    areaMax?: number;
}

export class TrovitSerpDataImpl implements TrovitSerpData {
    what: string;
    pageViewId: string;
    typeId: TrovitType;
    device: string;
    isSem: boolean;
    currentOrigin: number;
    originHost: string;
    operationType: string;
    page: number;
    numberOfListings: number;
    isRolledOutCountry: boolean;
    isDirectCountry: boolean;
    userHasInteracted: boolean;
    geoId?: string;
    roomsMin?: string;
    bathRoomsMin?: string;
    priceMin?: string;
    priceMax?: string;
    cityArea?: string;
    city?: string;
    region?: string;
    regionId?: number;
    cityId?: number;
    cityAreaId?: number;
    propertyType?: string;
    areaMin?: number;
    areaMax?: number;
    orderBy?: string;

    constructor(
        what: string,
        pageViewId: string,
        typeId: TrovitType,
        device: string,
        isSem: boolean,
        currentOrigin: number,
        originHost: string,
        operationType: string,
        page: number,
        numberOfListings: number,
        isRolledOutCountry: boolean,
        isDirectCountry: boolean,
        userHasInteracted: boolean,
        geoId?: string,
        roomsMin?: string,
        bathRoomsMin?: string,
        priceMin?: string,
        priceMax?: string,
        cityArea?: string,
        city?: string,
        region?: string,
        cityAreaId?: number,
        cityId?: number,
        regionId?: number,
        propertyType?: string,
        areaMin?: number,
        areaMax?: number,
        orderBy?: string,
    ) {
        this.what = what;
        this.pageViewId = pageViewId;
        this.typeId = typeId;
        this.device = device;
        this.isSem = isSem;
        this.currentOrigin = currentOrigin;
        this.originHost = originHost;
        this.operationType = operationType;
        this.page = page;
        this.numberOfListings = numberOfListings;
        this.isRolledOutCountry = isRolledOutCountry;
        this.isDirectCountry = isDirectCountry;
        this.userHasInteracted = userHasInteracted;
        this.geoId = geoId;
        this.roomsMin = roomsMin;
        this.bathRoomsMin = bathRoomsMin;
        this.priceMin = priceMin;
        this.priceMax = priceMax;
        this.cityArea = cityArea;
        this.city = city;
        this.region = region;
        this.cityAreaId = cityAreaId;
        this.cityId = cityId;
        this.regionId = regionId;
        this.propertyType = propertyType;
        this.areaMin = areaMin;
        this.areaMax = areaMax;
        this.orderBy = orderBy;
    }

    toTrovitUserSearchUrlParams(): TrovitUserSearchUrlParams {
        return {
            originHost: this.originHost,
            typeId: this.typeId.toString(),
            what: this.what,
            isSem: this.isSem,
            currentOrigin: this.currentOrigin,
            geoId: this.geoId,
            roomsMin: this.roomsMin,
            bathRoomsMin: this.bathRoomsMin,
            priceMin: this.priceMin,
            priceMax: this.priceMax,
            region: this.region,
            city: this.city,
            cityArea: this.cityArea,
            regionId: this.regionId,
            cityId: this.cityId,
            cityAreaId: this.cityAreaId,
            propertyType: this.propertyType,
            areaMin: this.areaMin?.toString(),
            areaMax: this.areaMax?.toString()
        };
    }

    static createWithDefaults(trovitSerpData: TrovitSerpData, originHost: string): TrovitSerpDataImpl {
        return new TrovitSerpDataImpl(
            trovitSerpData.what,
            trovitSerpData.pageViewId,
            trovitSerpData.typeId,
            trovitSerpData.device,
            trovitSerpData.isSem,
            trovitSerpData.currentOrigin,
            originHost,
            trovitSerpData.operationType,
            trovitSerpData.page,
            trovitSerpData.numberOfListings,
            trovitSerpData.isRolledOutCountry,
            trovitSerpData.isDirectCountry,
            trovitSerpData.userHasInteracted,
            trovitSerpData.geoId,
            trovitSerpData.roomsMin,
            trovitSerpData.bathRoomsMin,
            trovitSerpData.priceMin,
            trovitSerpData.priceMax,
            trovitSerpData.cityArea,
            trovitSerpData.city,
            trovitSerpData.region,
            trovitSerpData.cityAreaId,
            trovitSerpData.cityId,
            trovitSerpData.regionId,
            trovitSerpData.propertyType,
            trovitSerpData.areaMin,
            trovitSerpData.areaMax
        );
    }
}