import ServiceContainer from "../ServiceContainer";
import TypesSelectorSubscriber from "./subscribers/TypesSelectorSubscriber";
import BedroomsLinearSelectorFilterSubscriber from "./subscribers/BedroomsLinearSelectorFilterSubscriber";
import SearchBoxSubscriber from "./subscribers/SearchBoxSubscriber";
import SimilarListingsSubscriber from "./subscribers/SimilarListingsSubscriber";
import {ListingsSubscriber} from "./subscribers/ListingsSubscriber";
import FiltersModalSubscriber from "./subscribers/FiltersModalSubscriber";
import SimilarListingsService from "./services/SimilarListingsService";
import DuplicatedClickListingService from "./services/DuplicatedClickListingService";
import {RedirectToUserSearch} from "../core/url/services/RedirectToUserSearch";
import {
    FullScreenModalBlock,
    GetNumberOfListingsAjax,
    WindowService
} from "../core/url/services/GetNumberOfListingsAjax";
import {DocumentRedirectToUrl} from "../core/url/RedirectToUrl";
import {EventBus} from "../../../common/ts/events/EventBus";
import GoogleDisplay from "./components/googleDisplay";
import dom from "../../../common/ts/core/dom";
import {VisitedCookie} from "./VisitedCookie";
import {ApiListingsRepository} from "../core/api/ApiRepository";
import SortBySelectorSubscriber from "./subscribers/SortBySelectorSubscriber";
import PriceRangeSelectorSubscriber from "./subscribers/PriceRangeSelectorSubscriber";
import BathroomsLinearSelectorFilterSubscriber from "./subscribers/BathroomsLinearSelectorFilterSubscriber";
import AreaRangeSelectorSubscriber from "./subscribers/AreaRangeSelectorSubscriber";
import PropertyTypesSelectorSubscriber from "./subscribers/PropertyTypesSelectorSubscriber";
import CitiesSelectorSubscriber from "./subscribers/CitiesSelectorSubscriber";
import RegionsSelectorSubscriber from "./subscribers/RegionsSelectorSubscriber";
import {ToggleDescription} from "./subscribers/ToggleDescription";
import {ListingVisited} from "./subscribers/ListingVisited";
import FeatureFlags from "../../../common/ts/FeatureFlags";
import {FiltersSubscriber} from "./subscribers/FiltersSubscriber";
import CityAreasSelectorSubscriber from "./subscribers/CityAreasSelectorSubscriber";
import {ListingsFilteringResponseSubscriber} from "./subscribers/ListingFilteringResponseSubscriber";
import ClearAllFormFiltersSubscriber from "./subscribers/ClearAllFormFiltersSubscriber";
import ShowResultsSubscriber from "./subscribers/ShowResultsSubscriber";
import Sticky from "./components/Sticky";
import PointsOfCapture from "./components/retention/pointsOfCapture/PointsOfCapture";
import {GoogleOneTapSubscriber} from "./subscribers/GoogleOneTapSubscriber";
import AdFormService from "../../../common/ts/services/AdFormService";
import UserContactDataStorageService from "../../../common/ts/services/UserContactDataStorageService";
import TrackFilterWithGoogleAnalyticsService from "./services/TrackFilterWithGoogleAnalyticsService";
import GoogleTrackingFactory from "../../../common/ts/core/tracking/GoogleTrackingFactory";
import {Brand} from "../../../common/ts/types/Brand";
import {Country} from "../../../common/ts/types/Country";
import {TopLeftPointOfCapture} from "./components/retention/pointsOfCapture/TopLeftPointOfCapture";
import {BottomPointOfCapture} from "./components/retention/pointsOfCapture/BottomPointOfCapture";
import {AutoOpenModalPointOfCapture} from "./components/retention/pointsOfCapture/AutoOpenModalPointOfCapture";
import ConsentSubscriber from "./subscribers/ConsentSubscriber";
import {MobileFixedBottomPointOfCapture} from "./components/retention/pointsOfCapture/MobileFixedBottomPointOfCapture";
import CookieService from "../../../common/ts/services/CookieService";
import ClearAllFiltersAndReloadSubscriber from "./subscribers/ClearAllFiltersAndReloadSubscriber";

const eventBus: EventBus = EventBus.getInstance()
const featureFlags: FeatureFlags = new FeatureFlags()
const visitedCookie: VisitedCookie = new VisitedCookie()
const duplicateClickListingService: DuplicatedClickListingService = new DuplicatedClickListingService()
const modalBlock = new FullScreenModalBlock('filters-modal-block')
const adFormService = new AdFormService()
const userContactDataStorageService = new UserContactDataStorageService()
const cookieService = new CookieService()

const redirectToUserSearch: RedirectToUserSearch = new RedirectToUserSearch(new DocumentRedirectToUrl, featureFlags);
const getNumberOfListingsAjax = new GetNumberOfListingsAjax(new WindowService(), eventBus, featureFlags, modalBlock);
const topLeftPointOfCapture: TopLeftPointOfCapture = new TopLeftPointOfCapture(eventBus, userContactDataStorageService);
const bottomPointOfCapture: BottomPointOfCapture = new BottomPointOfCapture(eventBus, userContactDataStorageService);
const autoOpenModalPointOfCapture: AutoOpenModalPointOfCapture = new AutoOpenModalPointOfCapture(eventBus, userContactDataStorageService);
const mobileFixedBottomPointOfCapture: MobileFixedBottomPointOfCapture = new MobileFixedBottomPointOfCapture(eventBus, userContactDataStorageService, cookieService);
// In Trovit not is necessary to pass the brand and country
const googleTracking = GoogleTrackingFactory.createFor(
    "" as Brand,
    "" as Country
)
const trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService = new TrackFilterWithGoogleAnalyticsService(googleTracking);
export const serviceContainer: ServiceContainer = new ServiceContainer({
    [AreaRangeSelectorSubscriber.name]: () => new AreaRangeSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [AreaRangeSelectorSubscriber.name + '.ajax']: () => new AreaRangeSelectorSubscriber(eventBus, getNumberOfListingsAjax, trackFilterWithGoogleAnalyticsService),
    [BathroomsLinearSelectorFilterSubscriber.name]: () => new BathroomsLinearSelectorFilterSubscriber(
        eventBus,
        redirectToUserSearch,
        trackFilterWithGoogleAnalyticsService
    ),
    [BathroomsLinearSelectorFilterSubscriber.name + '.ajax']: () => new BathroomsLinearSelectorFilterSubscriber(
        eventBus,
        getNumberOfListingsAjax,
        trackFilterWithGoogleAnalyticsService
    ),
    [BedroomsLinearSelectorFilterSubscriber.name]: () => new BedroomsLinearSelectorFilterSubscriber(
        eventBus,
        redirectToUserSearch,
        trackFilterWithGoogleAnalyticsService
    ),
    [BedroomsLinearSelectorFilterSubscriber.name + '.ajax']: () => new BedroomsLinearSelectorFilterSubscriber(
        eventBus,
        getNumberOfListingsAjax,
        trackFilterWithGoogleAnalyticsService
    ),

    [CitiesSelectorSubscriber.name]: () => new CitiesSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [CitiesSelectorSubscriber.name + '.ajax']: () => new CitiesSelectorSubscriber(eventBus, getNumberOfListingsAjax, trackFilterWithGoogleAnalyticsService),
    [CityAreasSelectorSubscriber.name]: () => new CityAreasSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [CityAreasSelectorSubscriber.name + '.ajax']: () => new CityAreasSelectorSubscriber(eventBus, getNumberOfListingsAjax, trackFilterWithGoogleAnalyticsService),
    [FiltersSubscriber.name]: () => new FiltersSubscriber(redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [FiltersModalSubscriber.name]: () => new FiltersModalSubscriber(eventBus, redirectToUserSearch, modalBlock),
    [GoogleDisplay.name]: () => new GoogleDisplay(window.googletag, dom),
    [ListingVisited.name]: () => new ListingVisited(eventBus, visitedCookie),
    [ListingsSubscriber.name]: () => new ListingsSubscriber(
        eventBus,
        visitedCookie,
        duplicateClickListingService
    ),
    [PriceRangeSelectorSubscriber.name]: () => new PriceRangeSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [PriceRangeSelectorSubscriber.name + '.ajax']: () => new PriceRangeSelectorSubscriber(eventBus, getNumberOfListingsAjax, trackFilterWithGoogleAnalyticsService),
    [PropertyTypesSelectorSubscriber.name]: () => new PropertyTypesSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [PropertyTypesSelectorSubscriber.name + '.ajax']: () => new PropertyTypesSelectorSubscriber(eventBus, getNumberOfListingsAjax, trackFilterWithGoogleAnalyticsService),
    [RegionsSelectorSubscriber.name]: () => new RegionsSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [RegionsSelectorSubscriber.name + '.ajax']: () => new RegionsSelectorSubscriber(eventBus, getNumberOfListingsAjax, trackFilterWithGoogleAnalyticsService),
    [SearchBoxSubscriber.name]: () => new SearchBoxSubscriber(redirectToUserSearch),
    [SimilarListingsSubscriber.name]: () => new SimilarListingsSubscriber(
        eventBus,
        new SimilarListingsService(eventBus, new ApiListingsRepository()),
        visitedCookie
    ),
    [SortBySelectorSubscriber.name]: () => new SortBySelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [ToggleDescription.name]: () => new ToggleDescription(eventBus),
    [TypesSelectorSubscriber.name]: () => new TypesSelectorSubscriber(eventBus, redirectToUserSearch, trackFilterWithGoogleAnalyticsService),
    [ListingsFilteringResponseSubscriber.name]: () => new ListingsFilteringResponseSubscriber(eventBus),
    [ShowResultsSubscriber.name]: () => new ShowResultsSubscriber(redirectToUserSearch, modalBlock),
    [ClearAllFormFiltersSubscriber.name]: () => new ClearAllFormFiltersSubscriber(getNumberOfListingsAjax),
    [ClearAllFiltersAndReloadSubscriber.name]: () => new ClearAllFiltersAndReloadSubscriber(redirectToUserSearch),
    [Sticky.name]: () => new Sticky(),
    [PointsOfCapture.name]: () => new PointsOfCapture(adFormService, topLeftPointOfCapture, bottomPointOfCapture, userContactDataStorageService, autoOpenModalPointOfCapture, mobileFixedBottomPointOfCapture, eventBus, cookieService),
    [GoogleOneTapSubscriber.name]: () => new GoogleOneTapSubscriber(eventBus, adFormService),
    [ConsentSubscriber.name]: () => new ConsentSubscriber(eventBus),
});
