import PageData from "../../PageData";
import tracking from "../tracking";
import {EventBus} from "../../events/EventBus";
import {PERMISSION_SEND_COMMUNICATIONS} from "../../tracking/constants";
import http from "../http";

export class PermissionSendCommunicationsTracking {

    private readonly eventBus: EventBus;

    constructor() {
        tracking.init();
        http.init();
        this.eventBus = EventBus.getInstance();
    }

    public emitPermissionSendCommunicationsEvent = (pageData: PageData, contactModalElement: HTMLElement) => {
        let permissionSendCommunications = this.getPermissionSendCommunications(contactModalElement);
        if (permissionSendCommunications) {
            this.eventBus.emit(new tracking.TrackWebTrackingEvent(
                {
                    type: PERMISSION_SEND_COMMUNICATIONS,
                    serpUrl: window.location.href,
                    pageViewId: pageData.pageViewId,
                    value: permissionSendCommunications,
                }
            ));
        }
    }

    public getPermissionSendCommunications = (htmlElement: HTMLElement) => {
        if (!htmlElement) {
            return null
        }

        const permissionSendCommunications: HTMLInputElement = htmlElement.querySelector("#permissionSendCommunications")as HTMLInputElement;

        if (permissionSendCommunications === null) {
            return null;
        }

        return permissionSendCommunications.hasAttribute("checked").toString()
    }
}