import CookieService from "../../../../../../common/ts/services/CookieService";
import {PageDataFilters} from "./PointsOfCapture";

const ALERTS_CLOSED_COOKIE_NAME = 'trovitClosedAlerts'

export class CookieAlertsClosedRepository {
    private cookieService: CookieService;
    private alertsClosed: AlertClosedData[];

    constructor(cookieService: CookieService) {
        this.cookieService = cookieService;
        this.alertsClosed = [];
    }

    init() {
        const alertsClosedString = this.cookieService.getCookie(ALERTS_CLOSED_COOKIE_NAME);
        if (alertsClosedString) {
            this.alertsClosed = JSON.parse(alertsClosedString);
        }
    }

    add(alertClosedData: AlertClosedData) {
        this.alertsClosed.push(alertClosedData);
        this.cookieService.setExpirableCookie(ALERTS_CLOSED_COOKIE_NAME, JSON.stringify(this.alertsClosed), 7);
    }

    exist(alertClosedData: AlertClosedData) {
        return this.alertsClosed.map((element) => {
            return JSON.stringify(element)
        }).includes(JSON.stringify(alertClosedData));
    }
}

interface AlertClosedData {
    pointOfCapture: PointOfCapture,
    what: string,
    filters: PageDataFilters,
}

export enum PointOfCapture {
    TOP_LEFT, BOTTOM, AUTO_OPEN_MODAL, MOBILE_FIXED_BOTTOM,
}