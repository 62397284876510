import {EventBus} from "../../../../common/ts/events/EventBus";
import {
    ConsentCookieIsAlreadySetEvent,
    DidomiClickAgreeToAllEvent,
    DidomiNoticeClickAgreeEvent,
    DidomiNoticeClickDisagreeEvent, UserHasNotInteractedEvent, GoogleOneTapFlowEvent, WhitelabelEvent,
} from "../../../../common/ts/events/Events";
import GoogleOneTapComponent from "../../../../common/ts/components/core/GoogleOneTapComponent";
import {TrovitSerpData} from "../TrovitPageData";

export default class ConsentSubscriber {
    private readonly eventBus: EventBus

    constructor(eventBus: EventBus) {
        this.eventBus = eventBus
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(DidomiNoticeClickAgreeEvent.TYPE, {
            id: 'trovit-didomi-click-agree-subscriber',
            callback: this.triggerConsentCookieIsAlreadySetEvent.bind(this)
        });

        this.eventBus.subscribeWithId(DidomiNoticeClickDisagreeEvent.TYPE, {
            id: 'trovit-didomi-click-disagree-subscriber',
            callback: this.triggerConsentCookieIsAlreadySetEvent.bind(this)
        });
        this.eventBus.subscribeWithId(DidomiClickAgreeToAllEvent.TYPE, {
            id: 'trovit-didomi-click-agree-to-all-subscriber',
            callback: this.triggerConsentCookieIsAlreadySetEvent.bind(this)
        });
        this.eventBus.subscribeWithId(ConsentCookieIsAlreadySetEvent.TYPE, {
            id: 'trovit-consent--cookie-is-already-set-subscriber',
            callback: (_event: WhitelabelEvent) => {
                if (pageData.userHasInteracted) {
                    new GoogleOneTapComponent(this.eventBus).prompt();
                } else {
                    this.eventBus.emit(new UserHasNotInteractedEvent({flow: GoogleOneTapFlowEvent.NOT_USED}));
                }
            }
        });
    }

    private triggerConsentCookieIsAlreadySetEvent(_event: WhitelabelEvent) {
        this.eventBus.emit(new ConsentCookieIsAlreadySetEvent());
    }
}