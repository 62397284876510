import location from "../../../../common/ts/core/location";
import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {TrovitPageData, TrovitSerpDataImpl} from "../TrovitPageData";
import {TrovitType} from "../../core/TrovitType";
import TrackFilterWithGoogleAnalyticsService, {
    TrackFilterWithGoogleAnalyticsData
} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class TypesSelectorSubscriber {
    private eventBus: EventBus
    private userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitPageData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-types-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;
                if (payload.id === "types-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: 'type',
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData)
                    const type: string = payload.value;
                    const what: string = pageData.what;
                    pageData.typeId = type as unknown as TrovitType;
                    pageData.what = what;
                    const params = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.userSearch.execute(params);
                }
            }
        });
    }
}