import {EventBus} from "../../../../common/ts/events/EventBus";
import {
    ListingsFilteringResponseEvent,
} from "../../core/url/services/GetNumberOfListingsAjax";


const SHOW_RESULTS_BUTTON_ID = 'show-results-button'

export class ListingsFilteringResponseSubscriber {
    private readonly eventBus: EventBus;

    constructor(eventBus: EventBus) {
        this.eventBus = eventBus;
    }

    init() {
        this.eventBus.subscribeWithId(ListingsFilteringResponseEvent.TYPE, {
            id: 'trovit-listings-filtering-response-subscriber',
            callback: (event) => {
                const {payload}: ListingsFilteringResponseEvent = event as ListingsFilteringResponseEvent;
                this.showResultsOnButton(payload.numberOfFilteredResults)
                this.showResultsOnHeader(payload.numberOfFilteredResults)
            }
        });
    }

    private showResultsOnButton(numberOfFilteredResults: string): void {
        const numberOfListingsFilteredElement = document.getElementById(SHOW_RESULTS_BUTTON_ID);
        if (numberOfListingsFilteredElement) {
            const textContent = numberOfListingsFilteredElement.textContent;
            numberOfListingsFilteredElement.textContent = this.replaceNumberInText(
                textContent, numberOfFilteredResults);
        }
    }

    private showResultsOnHeader(numberOfFilteredResults: string): void {
        const resultsCounters = document.querySelectorAll('.results-counter')
        if (resultsCounters) {
            resultsCounters.forEach((element: Element) => {
                const textContent = element.textContent;
                element.textContent = this.replaceNumberInText(
                    textContent, numberOfFilteredResults);
            });
        }
    }

    private replaceNumberInText(text: string | null, numberOfResults: string): string {
        if (!text) return '';
        return text.replace(/\d{1,3}(?:[,. ]\d{3})*/g, numberOfResults.toString());
    }
}