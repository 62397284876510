import {TrovitType} from "../../core/TrovitType";

export interface DuplicateClickListingData {
    listingId: string;
    pageViewId: string;
    type: TrovitType;
    geoId?: string;
    excludedListingIds: string[];
    currentOrigin: number;
}

export default class DuplicatedClickListingService {
    public async execute(data: DuplicateClickListingData): Promise<string> {
        return fetch(`/wl/api/v1/listings/${data.listingId}/duplicated-click`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                pageViewId: data.pageViewId,
                typeId: data.type,
                geoId: data.geoId,
                excludedListingIds: data.excludedListingIds,
                currentOrigin: data.currentOrigin,
            }),
        }).then((response) => response.text());
    }
}
