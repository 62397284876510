import {SimilarListingsServiceQuery} from "../../serp/services/SimilarListingsServiceQuery";

export interface ListingsRepository {
    getSimilarListings(query: SimilarListingsServiceQuery): Promise<string>;
}

export class ApiListingsRepository implements ListingsRepository {
    public async getSimilarListings(query: SimilarListingsServiceQuery): Promise<string> {
        return fetch(`/wl/api/v1/listings/${query.listingId}/similar`, {
            method: 'POST',
            headers: {'Content-Type': 'application/json',},
            body: JSON.stringify({
                pageViewId: query.pageViewId,
                excludeListings: query.excludeListings,
                typeId: query.type,
                geoId: query.geoId,
                currentOrigin: query.currentOrigin,
            }),
        }).then((response) => response.text());
    }
}