import {EventBus} from "../../../../../../common/ts/events/EventBus";
import {ALERT_CREATED_EVENT_TYPE, PointOfCaptureFormContainer} from "./PointOfCaptureFormContainer";
import UserContactDataStorageService from "../../../../../../common/ts/services/UserContactDataStorageService";
import {
    AlertCreatedPayload,
    AlertCreationImpressionPayload,
    PointOfCaptureClosedPayload,
    SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE,
    TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE
} from "./PointsOfCapture";
import {
    FullPageModalClosedEvent,
    FullPageModalCloseEvent,
    FullPageModalOpenEvent,
    GoogleOneTapFlowEvent,
    GoogleOneTapSignInEvent,
    PointOfCaptureExitEvent, WebPushNotificationDeniedEvent,
    WebPushNotificationGrantedEvent,
    WhitelabelEvent
} from "../../../../../../common/ts/events/Events";
import {PointOfCapture} from "./CookieAlertsClosedRepository";

const AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID = "auto-open-retention-modal";

export class AutoOpenModalPointOfCapture {
    private readonly eventBus: EventBus;
    private deviceDetectorByCss: HTMLElement | null;
    private htmlElement: HTMLElement | null;
    private formContainer: PointOfCaptureFormContainer;
    private flow: GoogleOneTapFlowEvent | null;

    constructor(eventBus: EventBus, storage: UserContactDataStorageService) {
        this.eventBus = eventBus;
        this.htmlElement = null;
        this.deviceDetectorByCss = null;
        this.formContainer = new PointOfCaptureFormContainer(storage, this.eventBus);
        this.flow = null;
    }

    init(alertAlreadyCreated: boolean, alertAlreadyClosed: (pointOfCapture: PointOfCapture) => boolean) {
        this.htmlElement = document.getElementById(AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID);
        this.deviceDetectorByCss = document.getElementById(`${AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID}-device`);
        if (this.htmlElement == null || alertAlreadyCreated || alertAlreadyClosed(PointOfCapture.AUTO_OPEN_MODAL)) {
            this.removeMe();
            if (this.deviceDetectorByCss?.checkVisibility()) {
                this.subscribeToEventsWithoutPointOfCapture()
            }
            return;
        } else {
            this.subscribeToEventsWithPointOfCapture()
        }
    }

    private subscribeToEventsWithPointOfCapture() {
        this.subscribeToEventsInitialize(this.launch);
    }

    private subscribeToEventsInitialize(callback: (event: WhitelabelEvent) => void) {
        this.eventBus.subscribeWithId(
            GoogleOneTapSignInEvent.TYPE,
            {
                id: `${GoogleOneTapSignInEvent.TYPE}-auto-open-modal-handler`,
                callback: callback,
            }
        )
        this.eventBus.subscribeWithId(
            WebPushNotificationGrantedEvent.TYPE,
            {
                id: `${WebPushNotificationGrantedEvent.TYPE}-auto-open-modal-handler`,
                callback: (event) => {
                    if (this.flow == GoogleOneTapFlowEvent.CLOSE) {
                        callback(event);
                    }
                },
            }
        )
        this.eventBus.subscribeWithId(
            WebPushNotificationDeniedEvent.TYPE,
            {
                id: `${WebPushNotificationDeniedEvent.TYPE}-auto-open-modal-handler`,
                callback: (event) => {
                    if (this.flow == GoogleOneTapFlowEvent.CLOSE) {
                        callback(event);
                    }
                },
            }
        )
    }

    private launch = (event: WhitelabelEvent) => {
        const {payload: {flow}} = (event as PointOfCaptureExitEvent);
        this.flow = flow;
        if (this.htmlElement) {
            this.formContainer.init(this.htmlElement);
            if (this.formContainer.isContentVisible()) {
                this.openModal();
                this.registerEvents();
                this.subscribeToEvents();
                // this.subscribeToEvents();
            }
        }
    }

    private subscribeToEvents() {
        this.eventBus.subscribeWithId(
            FullPageModalClosedEvent.TYPE,
            {
                id: AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID,
                callback: this.onCloseModal,
            }
        );
    }

    private onCloseModal = (event: WhitelabelEvent) => {
        const {payload}: FullPageModalClosedEvent = event as FullPageModalClosedEvent;
        if (payload.id == AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID) {
            document.dispatchEvent(new CustomEvent<PointOfCaptureClosedPayload>(SAVE_POINT_OF_CAPTURE_CLOSED_EVENT_TYPE, {detail: {'type': PointOfCapture.AUTO_OPEN_MODAL}}));
            this.emitNextStepEvent();
        }
    }

    private openModal() {
        this.eventBus.emit(new FullPageModalOpenEvent({id: AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID}));
        this.dispatchAlertCreationImpression(this.formContainer.getVisibleSection()!!);
        this.formContainer.applyFocusToInput();
    };

    private registerEvents() {
        this.registerAlertCreatedEvent();
    }

    private dispatchAlertCreationImpression(section: number) {
        document.dispatchEvent(new CustomEvent<AlertCreationImpressionPayload>(TRACK_ALERT_CREATION_IMPRESSION_EVENT_TYPE, {detail: {'section': section}}));
    }

    private registerAlertCreatedEvent() {
        // @ts-ignore
        document.addEventListener(ALERT_CREATED_EVENT_TYPE, this.alertCreated);
    }

    private alertCreated = (e: CustomEvent<AlertCreatedPayload>) => {
        this.emitNextStepEvent();
        setTimeout(this.removeMe, 3000);
    }

    private removeMe = () => {
        this.eventBus.emit(new FullPageModalCloseEvent({id: AUTO_OPEN_POINT_OF_CAPTURE_MODAL_ID}));
        this.htmlElement?.remove();
    }

    private subscribeToEventsWithoutPointOfCapture() {
        this.subscribeToEventsInitialize(this.emitNextStepWithoutPointOfCapture);
    }

    private emitNextStepWithoutPointOfCapture = (event: WhitelabelEvent) => {
        const {payload: {flow}} = (event as PointOfCaptureExitEvent);
        this.flow = flow;
        this.emitNextStepEvent();
    }

    private emitNextStepEvent() {
        this.eventBus.emit(new PointOfCaptureExitEvent({flow: this.flow!!}));
    }
}
