import {UserSearch} from "../../core/url/services/RedirectToUserSearch";
import location from "../../../../common/ts/core/location";
import {EventBus} from "../../../../common/ts/events/EventBus";
import {SelectChangeEvent} from "../../../../common/ts/events/Events";
import {TrovitSerpData, TrovitSerpDataImpl} from "../TrovitPageData";
import TrackFilterWithGoogleAnalyticsService, {TrackFilterWithGoogleAnalyticsData} from "../services/TrackFilterWithGoogleAnalyticsService";

export default class CityAreasSelectorSubscriber {

    private readonly eventBus: EventBus
    private readonly userSearch: UserSearch
    private readonly trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService

    constructor(
        eventBus: EventBus,
        userSearch: UserSearch,
        trackFilterWithGoogleAnalyticsService: TrackFilterWithGoogleAnalyticsService
    ) {
        this.eventBus = eventBus
        this.userSearch = userSearch
        this.trackFilterWithGoogleAnalyticsService = trackFilterWithGoogleAnalyticsService
    }

    init(pageData: TrovitSerpData) {
        this.eventBus.subscribeWithId(SelectChangeEvent.TYPE, {
            id: 'trovit-serp-cityareas-selector-changed-subscriber',
            callback: (event) => {
                const {payload}: SelectChangeEvent = event as SelectChangeEvent;
                if (payload.id === "cityareas-selector") {
                    this.trackFilterWithGoogleAnalyticsService.execute({
                        value: 'cityAreas', // This value should match with the value used for Desktop in FiltersSubscriber.ts
                        isSem: pageData.isSem,
                    } as TrackFilterWithGoogleAnalyticsData);
                    const selectedCityAreaId = parseInt(payload.value);
                    const selectCityAreaElement = document.getElementById(`${payload.id}`) as HTMLSelectElement;
                    const selectedCityAreaLabel = selectCityAreaElement.options[selectCityAreaElement.selectedIndex].text;
                    if (selectedCityAreaId) {
                        pageData['cityArea'] = selectedCityAreaLabel
                        pageData['cityAreaId'] = selectedCityAreaId
                    } else {
                        delete pageData['cityArea']
                        delete pageData['cityAreaId']
                    }
                    const userSearchFilters = TrovitSerpDataImpl.createWithDefaults(
                        pageData,
                        location.getOrigin()
                    ).toTrovitUserSearchUrlParams();
                    this.userSearch.execute(userSearchFilters);
                }
            }
        });
    }
}